<template>
  <div
    :class="['paypal-client-btn', randomKey]"
  >
    <!-- <slot v-if="!oneTime || !paypalBtnInit"></slot> -->
    <slot></slot>
  </div>
</template>

<script name="BPaypalGaButton" setup lang="ts">
import { computed } from 'vue'
import {
  PaypalGaManager,
  bsPayEventBus,
  PayPalButtonStatus,
  type LoadPaypalGaButtonOptions,
  type ButtonStyle,
} from '@shein-aidc/bs-sdk-libs-pay'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
// import { debuggerLog } from '../../../utils'

const appConfigs = useAppConfigs()

const { NODE_SERVER_ENV } = appConfigs?.$envs || {}
const isDev = ['debug', 'localhost'].includes(NODE_SERVER_ENV)

const paypalChannelMap = {
  PAYPAL: 'PayPal-GApaypal',
  PAYLATER: 'PayPal-bnpl',
  VENMO: 'PayPal-Venmo',
}

interface BPaypalButtonProps {
  oneTime?: boolean;
  currency?: string;
  countryCode?: string;
  paymentCode?: string;
  onComplete?: (status: PayPalButtonStatus, err?: Error) => void;
  // paypalStyle?: ButtonStyle;
  createPaymentOptions: LoadPaypalGaButtonOptions['createPaymentOptions'];
  onClick: LoadPaypalGaButtonOptions['onClick'];
  onApproved: LoadPaypalGaButtonOptions['onApproved'];
  onCancel: LoadPaypalGaButtonOptions['onCancel'];
  onError: LoadPaypalGaButtonOptions['onError'];
}

const props = withDefaults(defineProps<BPaypalButtonProps>(), {
  oneTime: true,
})
const emit = defineEmits(['info-change', 'handle-paypal-btn-init'])

// const paypalBtnInit = ref(false)

const randomKey = `pp-render-btn-${Math.random().toString(36).substring(8)}`

const paypalStyle = {
  color: 'black',
  shape: 'rect',
  label: 'paypal',
  height: 44,
} as ButtonStyle

const isPaypalChannel = computed(() => [paypalChannelMap.PAYPAL, paypalChannelMap.PAYLATER].includes(props?.paymentCode ?? ''))

const sdkConfig = computed(() => {
  const config: LoadPaypalGaButtonOptions['sdkConfig'] = {
    intent: 'capture',
    components: 'messages',
  }
  if (isPaypalChannel.value) {
    config.components = 'buttons,messages'
    config.currency = props?.createPaymentOptions?.currency
  }
  if(props?.paymentCode === paypalChannelMap.PAYLATER) {
    if(isDev) {
      config['buyer-country'] = props?.countryCode
    }
    config['enable-funding'] = 'paylater'
  }
  return config
})

const fundingSource = computed(() => {
  return props?.paymentCode === paypalChannelMap.PAYPAL ? 'paypal' : 'paylater'
})

// const handleRenderComplete = (status) => {
//   paypalBtnInit.value = !!status
// }

const initializePPGA = async (paySessionInfo) => {
  const ppgaSessionInfo = paySessionInfo?.[PaypalGaManager?.PAYMENT_CODE ?? ''] || {}
  if (ppgaSessionInfo.clientToken) {
    await PaypalGaManager.initPPGAPay({
      clientToken: ppgaSessionInfo.clientToken,
      profileId: ppgaSessionInfo.profileId,
    })
    const deviceData = await PaypalGaManager.getPaypalDeviceInfo(ppgaSessionInfo.clientToken)
    emit('info-change', { paypalDeviceId: deviceData })
  }
}

const loadPaypalGaBtn = () => {
  PaypalGaManager.loadPaypalGaBtn({
    createPaymentOptions: props?.createPaymentOptions,
    sdkConfig: sdkConfig.value,
    fundingSource: fundingSource.value,
    paypalStyle,
  })
}

PaypalGaManager.registerPPGAPay({
  onClick: props.onClick,
  onApproved: props.onApproved,
  onCancel: props.onCancel,
  onError: props.onError,
  onComplete: props.onComplete,
  ele: `.${randomKey}`,
})

bsPayEventBus?.paypalLoadStatus?.on(({ status }) => {
  if (status === PayPalButtonStatus.RENDER_PAYPAL_BUTTON_SUCCESS) {
    emit('handle-paypal-btn-init', true)
  } else if(status === PayPalButtonStatus.RENDER_PAYPAL_BUTTON_ERROR) {
    emit('handle-paypal-btn-init', false)
  } else if(status === PayPalButtonStatus.INIT_PAYPAL_SDK_ERROR) {
    emit('handle-paypal-btn-init', false)
  }
})

bsPayEventBus.updatePaymentInfo.on(({ paySessionInfo }) => {
  emit('handle-paypal-btn-init', false)
  if(PaypalGaManager.getPaypalGaInstance()){
    isPaypalChannel.value && loadPaypalGaBtn()
    return
  }
  initializePPGA(paySessionInfo).then(() => {
    isPaypalChannel.value ? loadPaypalGaBtn() : PaypalGaManager.loadPaypalMessage({ sdkConfig: sdkConfig.value })
  })
})

</script>

<style lang="less" scoped>
.paypal-client-btn {
  width: 100%;
  height: 44/37.5rem;
}

</style>
