import { ref, watch, computed } from 'vue'
import {
  bsPayEventBus,
  PayPalButtonStatus,
} from '@shein-aidc/bs-sdk-libs-pay'

export const usePaypalBnplMessage = ({ paymentsStore, paypalBnplCurrencySupportList }) => {
  const paypalMessageInit = ref<boolean>(false)
  const isSupportBnpl = ref<boolean>(false)

  const paypalMessageShow = computed(() => {
    return paypalMessageInit.value && isSupportBnpl.value
  })

  const totalPriceAmount = computed(() => {
    return paymentsStore?.checkoutInfo?.totalPriceInfo?.amount || '0.00'
  })

  watch(() => paymentsStore?.checkoutInfo?.orderCurrencyInfo?.code, (newVal) => {
    // 币种发生变化
    if (paypalBnplCurrencySupportList.value?.includes(newVal)) {
      isSupportBnpl.value = true
    } else {
      isSupportBnpl.value = false
    }
  }, {
    immediate: true,
    deep: true,
  })

  const messageInitSuccess: PayPalButtonStatus[] = [
    PayPalButtonStatus.LOAD_PAYPAL_MESSAGE_SUCCESS,
    PayPalButtonStatus.INIT_PAYPAL_SDK_SUCCESS,
  ]

  const messageInitFail: PayPalButtonStatus[] = [
    PayPalButtonStatus.LOAD_PAYPAL_MESSAGE_ERROR,
    PayPalButtonStatus.INIT_PAYPAL_SDK_ERROR,
    PayPalButtonStatus.RENDER_PAYPAL_BUTTON_ERROR,
  ]

  bsPayEventBus?.paypalLoadStatus?.on(({ status }) => {
    if (messageInitSuccess.includes(status)) {
      paypalMessageInit.value = true
    } else if(messageInitFail.includes(status)) {
      paypalMessageInit.value = false
    }
  })

  return {
    paypalMessageShow,
    totalPriceAmount,
  }
}
