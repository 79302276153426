<template>
  <div
    class="bin-coupons-block"
    @click="handleClickBinCoupons"
  >
    <div
      class="pay-bin-coupons"
    >
      <span class="txt txt-discount">
        {{ title }}
      </span>
      <span
        v-if="cardName"
        class="txt txt-normal-pay"
      >
        {{ cardName }}
      </span>
      <span
        v-if="!isUseCoupon"
        class="txt"
      >
        &gt;
      </span>
    </div>
  </div>
</template>

<script name="DiscountItem" setup lang="ts">
/**
 * 卡bin优惠券组件
 */
import { computed } from 'vue'
import { usePaymentsStore } from '../../../../hooks/usePayments'

interface paymentsPreferentialTipsType {
  paymentsPreferentialTipsItem: {
    titles: string[]
    imageUrl: string
    type: string
    articleId: string
    uiType: string
    hasUsedCardBinCoupon: string | null
    cardBinCouponCode: string | null
  }
}

const emits = defineEmits(['handle-bs-payment-action'])

const props = withDefaults(defineProps<paymentsPreferentialTipsType>(), {
  paymentsPreferentialTipsItem: () => ({
    titles: [],
    imageUrl: '',
    type: '',
    articleId: '',
    uiType: '',
    hasUsedCardBinCoupon: null,
    cardBinCouponCode: null,
  }),
})

const paymentsStore = usePaymentsStore()

const isUseCoupon = computed(() => {
  return props.paymentsPreferentialTipsItem?.hasUsedCardBinCoupon === '1' // 是否使用卡bin优惠券。0-没有使用 1-已使用
})

const title = computed(() => {
  return props.paymentsPreferentialTipsItem?.titles?.[0]
})

const cardName = computed(() => {
  return props.paymentsPreferentialTipsItem?.titles?.[1]
})

const handleClickBinCoupons = () => {
  if (isUseCoupon.value) {
    return
  }
  paymentsStore?.handleBsPaymentAction({
    action: 'couponDrawerVisible',
    payload: {
      code: props.paymentsPreferentialTipsItem?.cardBinCouponCode || '',
    },
  })

  emits('handle-bs-payment-action', {
    action: 'couponDrawerVisible',
    payload: {
      code: props.paymentsPreferentialTipsItem?.cardBinCouponCode || '',
    },
  })
}

</script>

<style scoped lang="less">
.bin-coupons-block {
  display: flex;
  align-items: center;
  max-width: 100%;
  .pay-bin-coupons {
    display: flex;
    align-items: center;
    margin-bottom: unset;
    border: 1/75rem #ffe2cf solid;
    border-radius: 4/75rem;
    background-color: #fff6f3;
    padding: 0.05333333rem 15/75rem;
    position: relative;
    color: var(---sui_color_discount_dark, #c44a01);
    font-size: 10/37.5rem;
    font-family: SF UI Text;
    font-weight: 600;
    word-wrap: break-word;
    line-height: 1;
    max-width: 100%;

    // 超出一行展示省略号
    .txt {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .txt-discount {
      max-width: 96%;
      margin-right: 4/37.5rem;
    }
    .txt-discount.txt-normal-discount {
      max-width: 220/37.5rem;
    }
    .txt-normal-pay {
      flex: 1;
      position: relative;
      padding-left: 4/37.5rem;
      margin-right: 4/37.5rem;
      font-weight: 400;
      &::before {
        box-sizing: border-box;
        content: '';
        position: absolute;
        top: 20%;
        left: 0;
        width: 1/75rem;
        height: 70%;
        border-left: 1/75rem solid var(---sui_color_discount_dark, #c44a01);
      }
    }
    .txt-small {
      font-weight: 400;
    }
  }

  .add-tip {
    margin: 0 8/75rem;
  }
}
.bin-coupons-block.not-last {
  .pay-bin-coupons {
    max-width: 96%;
  }
}
.payment-options-item__coupons_txt.not-last {
  margin-bottom: -0.05rem;
}
.pay-item-token__bin_coupons.not-last {
  margin-bottom: unset;
}
.discount-tips__coupons_txt {
  margin-bottom: 0;
}

.coupons-before-after() {
  box-sizing: border-box;
  content: '';
  position: absolute;
  top: 50%;
  width: 7/75rem;
  height: 14/75rem;
  border: 1/75rem solid #ffe2cf;
  transform: translateY(-50%);
  background-color: white;
}

.pay-bin-coupons::before {
  .coupons-before-after();
  left: -2/75rem;
  border-left-color: #fff;
  border-radius: 0 7/75rem 7/75rem 0;
}

.pay-bin-coupons::after {
  .coupons-before-after();
  right: -2/75rem;
  border-right-color: #fff;
  border-radius: 7/75rem 0 0 7/75rem;
}
</style>
