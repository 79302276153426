<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <SDialog
      :visible="data.showPopup"
      :close-on-click-modal="false"
      show-close
      @close="handlePopupClose"
    >
      <img
        class="cod-verify-free__icon"
        :src="ICONUrl"
        :alt="langText.BS_KEY_PAY_1111"
      />
      <p class="cod-verify-free__title">
        {{ langText.BS_KEY_PAY_1111 }}
      </p>
      <p class="cod-verify-free__desc">
        {{ langText.BS_KEY_PAY_1112 }}
      </p>
      <div class="cod-verify-free__btn">
        <SButton
          :type="['primary']"
          :limit-click="true"
          :limit-duration="1000"
          @click="goFreeVerify(false)"
        >
          {{ data.needCountdown && data.countdown >= 0 ? template(`${data.countdown}s`, langText.BS_KEY_PAY_1114) : langText.BS_KEY_PAY_1113 }}
        </SButton>
      </div>
    </SDialog>

    <SDialog :visible.sync="data.goVerifyTipShow">
      <p>{{ data.freeVerifyErr }}</p>
      <template #footer>
        <SButton
          width="100%"
          :type="['primary']"
          @click="goVerifyConfirm"
        >
          {{ langText.BS_KEY_PAY_1116 }}
        </SButton>
      </template>
    </SDialog>
  </div>
</template>

<script name="CodVerifyFreePopup" lang="ts" setup>
import { Button as SButton, Dialog as SDialog } from '@shein/sui-mobile'
import { onMounted, onBeforeMount, computed } from 'vue'
import { template } from '@shein/common-function'
import useCodVerifyFree from '../../../hooks/useCodVerifyFree'
import { useAnalysis } from '../../../common/analysisSource'
const { triggerNotice } = useAnalysis()

const props = defineProps<{
  language: Record<string, string>;
}>()

const ICONUrl = 'https://img.ltwebstatic.com/images3_ccc/2024/06/21/6c/1718969768012157e3ff4aaf0c8f7143a48e019de6.png'

const { fetchCodVerifyFreeApi, fetchLanguage, langText, store: data } = useCodVerifyFree(props)

const validBillno = computed(() => {
  if(data.value.order.sub_billnos && data.value.order.sub_billnos.length){
    // 兼容 目前只有一个子订单时不会返回sub_billnos
    if (data.value.order.sub_billnos.length === 1 && data.value.order.sub_billnos[0].sub_billno) {
      return data.value.order.sub_billnos[0].sub_billno
    }
    if (data.value.order.sub_billnos.length > 1) {
      return data.value.order.relation_billno
    }
    return data.value.order.billno
  }
  return (data.value.order && data.value.order.billno) || ''
})

const stopCountDown = () => {
  clearInterval(data.value.timer)
  data.value.countdown = -1
}

const handleClose = () => {
  // 关闭埋点, 弹窗没有关闭场景，这里监听页面卸载
  triggerNotice({
    id: 'click_cod_freeverifypopup_close.comp_cod_verify_free_popup',
  })
}

const goFreeVerify = (isAuto) => {
  data.value.onStartValidate()

  data.value.errInfo = {}

  stopCountDown()

  // 点击确认验证埋点
  triggerNotice({
    id: 'click_cod_freeverifypopup.comp_cod_verify_free_popup',
    data: {
      order_id: data.value.order.billno,
      uorder_id: data.value.order.relation_billno,
      is_default_confirm: isAuto ? 1 : 0,
    },
  })

  fetchCodVerifyFreeApi({
    billno: validBillno.value,
  }).then(res => {
    data.value.errInfo = res
    if (res.code == 0) {
      handleClose()
      data.value.onValidateSuccess()
    } else {
      let tip = langText.value.BS_KEY_PAY_1115

      data.value.freeVerifyErr = tip
      data.value.goVerifyTipShow = true
      data.value.onValidateFail()
    }
  })
    .catch(() => {
      data.value.goVerifyTipShow = true
      data.value.onValidateFail()
    })
}

const goVerifyConfirm = () => {
  // this.freeVerify.isFree = false
  // this.sendMessageFn()
  data.value.goVerifyTipShow = false

  handleClose()

  data.value.onClose()
}

const show = () => {
  data.value.showPopup = true
  triggerNotice({
    id: 'expose_cod_freeverifypopup.comp_cod_verify_free_popup',
    data: {
      order_id: data.value.order.billno,
      uorder_id: data.value.order.relation_billno,
      is_default_confirm: data.value.needCountdown ? 1 : 0,
    },
  })
}

const handlePopupClose = () => {
  // 停止倒计时
  stopCountDown()
  data.value.onClose({ showBackTips: true })
}

onMounted(() => {
  if (data.value.needCountdown) {
    data.value.timer = setInterval(() => {
      data.value.countdown -= 1
      if (data.value.countdown <= 0) {
        clearInterval(data.value.timer)
        // 确认验证
        goFreeVerify(true)
      }
    }, 1000)
  }
})

onBeforeMount(() => {
  data.value.onCreate()
  // 获取多语言文案
  fetchLanguage({
    langs: {
      'BS_KEY_PAY_1111': 'SHEIN_KEY_PWA_31738',
      'BS_KEY_PAY_1112': 'SHEIN_KEY_PWA_31739',
      'BS_KEY_PAY_1113': 'SHEIN_KEY_PWA_31740',
      'BS_KEY_PAY_1114': 'SHEIN_KEY_PWA_31741',
      'BS_KEY_PAY_1115': 'SHEIN_KEY_PWA_16968',
      'BS_KEY_PAY_1116': 'SHEIN_KEY_PWA_15150',
    },
  }).then(res => {
    data.value.language = res
  }).catch(e => {
    // console.log(e)
  })
})

defineExpose({
  show,
})
</script>

<style lang="less">
.cod-verify-free__icon {
  width: 2rem;
  height: 2rem;
  margin: 8/37.5rem auto 12/37.5rem;
  display: block;
}
.cod-verify-free__title {
  font-size: 16/37.5rem;
  line-height: 19/37.5rem;
  font-weight: 700;
  font-family: SF UI Text;
  color: var(--sui_color_gray_dark1, #000);
  margin-bottom: 12/37.5rem;
  text-align: center;
}

.cod-verify-free__desc {
  font-size: 14/37.5rem;
  line-height: 17/37.5rem;
  font-family: SF UI Text;
  color: var(--sui_color_gray_dark1, #000);
  margin-bottom: 28/37.5rem;
  text-align: center;
}
.cod-verify-free__btn {
  padding-bottom: 20/37.5rem;
  button {
    width: 100%;
  }
}
</style>
