<template>
  <SDialog
    :visible.sync="visibleTips"
    :show-close="true"
    append-to-body
    @close-from-icon="handleActivelyClose"
    @close-from-mask="handleActivelyClose"
  >
    <p
      v-html="language.BS_KEY_PAY_1180"
    ></p>
    <template #footer>
      <SButton
        :type="['primary']"
        :width="'100%'"
        @click="handleConfirm"
      >
        {{ language.BS_KEY_PAY_1157 }}
      </SButton>
    </template>
  </SDialog>
</template>

<script setup>
import { Dialog as SDialog, Button as SButton } from '@shein/sui-mobile'
import { computed } from 'vue'

const emits = defineEmits(['update:visible', 'actively-close', 'confirm'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  language: {
    type: Object,
    default: () => ({}),
  },
})

const visibleTips = computed({
  get: () => props.visible,
  set: (val) => {
    emits('update:visible', val)
  },
})

const handleActivelyClose = () => {
  emits('actively-close')
}

const handleConfirm = () => {
  visibleTips.value = false
  emits('confirm')
}

</script>
