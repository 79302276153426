<template>
  <div
    class="remember-card-field"
    @click.prevent="emits('click')"
  >
    <label class="remember-card-field__text">
      {{ text }}
      <Icon
        v-if="showTips"
        class="remember-card-field__icon"
        name="sui_icon_doubt_16px_2"
        size="16px"
        color="#959595"
        :is-rotate="cssRight"
        @click.stop.prevent="emits('click-tips')"
      />
    </label>
    <SCheckboxSwitch
      v-model="switchValue"
      :disabled="disabled"
      :true-label="1"
      :false-label="0"
      @change="handleSwitchChange"
    />
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { CheckboxSwitch as SCheckboxSwitch } from '@shein/sui-mobile'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

const props = defineProps({
  text: {
    type: String,
    default: '',
  },
  disabled: Boolean,
  value: {
    type: Number,
    default: 0,
  },
  showTips: {
    type: Boolean,
    default: true,
  },
})
const emits = defineEmits(['input', 'change', 'click-tips', 'click'])
const switchValue = ref(props.value)

const { cssRight } = useAppConfigs()?.$envs || {}

watch(() => props.value, (value) => {
  switchValue.value = value ? 1 : 0
})

const handleSwitchChange = (data) => {
  console.log('switch change', data)
  // emits('input', data.value)
  emits('change', data.value)
}

</script>

<style lang="less">
.remember-card-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24/75rem;
  .remember-card-field__text {
    display: inline-flex;
    align-items: center;
    flex: 1;
    font-size: 14px;
    font-weight: 500;
    color: var(---sui_color_gray_dark1, #000);
    padding-right: 48/75rem;
  }
  .remember-card-field__icon {
    cursor: pointer;
    margin-left: 4/75rem;
  }
}
</style>
