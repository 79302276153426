<template>
  <div
    v-if="!!tips"
    class="bin-co-brand-card-info">
    <div>
      <span v-html="tips"></span>
      <Icon
        v-if="articleUrl"
        class="bin-co-brand-card-info__icon"
        name="sui_icon_doubt_16px_2"
        color="#959595"
        size="16px"
        @click="handleClickPreferentialTips"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import { processCoBrandCardBenefits } from '../../../hooks/useCoBrandCard'

const props = withDefaults(defineProps<{
  preferentialTips: Trade_PayToolKit.PaymentsPreferentialTips,
}>(), {})

const tips = computed(() => {
  return processCoBrandCardBenefits(props.preferentialTips)
})

const articleUrl = computed(() => {
  return props.preferentialTips?.articleId
})

const handleClickPreferentialTips = () => {
  location.href = articleUrl.value
}

</script>

<style scoped lang="less">
.bin-co-brand-card-info {
  padding: 0 12*2/75rem;
  > div {
    display: inline-block;
    padding: 2*2/75rem 6*2/75rem;
    background-color: var(---sui_color_promo_bg, #fff6f3);
    border: .5px solid rgba(196, 74, 1, .3);
    font-size: 10*2/75rem;
    line-height: 12*2/75rem;
    color: #C44A01;

    .bin-co-brand-card-info__icon {
      margin-left: 4*2/75rem;
    }
  }
}
</style>
