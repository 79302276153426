import { BankInfo, CollectInfoItem } from '../types'
import type { PaymentsStore } from './usePayments'
import { useEditablePaymentInfo } from './useEditablePaymentInfo'
import { Trade_PayToolKit } from '@shein-aidc/types-trade'

export const useSelectBank = (paymentStore?: PaymentsStore) => {
  // const paymentEventBus = useInjectPaymentEventBus()
  const { handleEditableInfoChange } = useEditablePaymentInfo(paymentStore)

  const handleCloseBankSelect = () => {
    paymentStore?.mergeState('bankSelectInfo', {
      visible: false,
      banks: [],
    })
  }
  const handleSelectedBankInfo = ({
    paymentCode,
    bankInfo,
    collectInfo,
  }:{ paymentCode: string; bankInfo?: BankInfo, collectInfo?: CollectInfoItem }) => {
    paymentStore?.mergeState('bankSelectInfo', {
      visible: false,
      selectedBank: bankInfo || {},
    })

    let changeInfo = {} as Trade_PayToolKit.EditablePaymentItem

    if (bankInfo) {
      changeInfo = {
        display_logo: bankInfo?.logo,
        display_title: bankInfo?.name,
        bank_code: bankInfo?.code,
        bank_name: bankInfo?.name,
      }
    }

    if (collectInfo) {
      changeInfo = {
        ...changeInfo,
        pay_email: collectInfo?.email,
        pay_phone: collectInfo?.phone,
      }
    }

    handleEditableInfoChange({
      changeInfo: {
        [paymentCode]: changeInfo,
      },
      needEmitChangeEvent: true,
    })
  }

  // const handleAutoVisibleBankSelect = (paymentCode: string) => {
  //   paymentStore?.mergeState('bankSelectInfo', {
  //     visible: true,
  //     banks: bankList.value,
  //     selectedBank: selectBankInfoCache.value?.bankInfo,
  //     paymentCode: props.paymentItem.code,
  //   })
  // }

  return {
    handleSelectedBankInfo,
    handleCloseBankSelect,
  }
}
