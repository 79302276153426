<template>
  <div
    class="check-type"
    :class="{ 'bottom-line': showBottomLine && enabled, 'full-line': fullLine }"
    @click.stop.prevent
  >
    <ClientOnly>
      <SRadio
        :value="signUpSelected"
        :label="checkedLabel"
        :reverse-select="true"
        :disabled="enabled !== '1'"
        theme="icon"
        gap="0"
        inline
        class="check-radio"
        @change="onSignUpChange"
      >
        <span class="desc">{{ desc }}</span>
      </SRadio>
      <!-- <span
        v-if="extraDesc"
        class="extra-desc"
      >{{ extraDesc }}</span> -->
    </ClientOnly>
  </div>
</template>

<script name="ChannelPaymentSignUp" lang="ts" setup>
import ClientOnly from 'vue-client-only'
import { Radio as SRadio } from '@shein/sui-mobile'

interface ChannelPaymentSignUpProps {
  signUpSelected: string
  checkedLabel?: string
  desc: string
  // extraDesc?: string
  showBottomLine?: boolean
  fullLine?: boolean
  enabled: string | number
}

const props = withDefaults(defineProps<ChannelPaymentSignUpProps>(), {
  signUpSelected: '',
  checkedLabel: '1',
  desc: '',
  showBottomLine: false,
  fullLine: false,
  enabled: '',
})

const emits = defineEmits(
  ['select'],
)

const onSignUpChange = () => {
  emits('select', props.signUpSelected)
}


</script>

<style lang="less" scoped>
.font-dpr(@font-size) {
    font-size: @font-size * 0.5;
}

.check-type {
  padding: 12/75rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &.bottom-line {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 120%;
      height: 1px;
      background-color: @sui_color_gray_weak1;
      // transform: scaleY(0.5);
    }
    &.full-line:after {
      width: 100%;
    }
  }
  .desc {
    .font-dpr(24px);
    line-height: 1;
  }
  .check-radio {
    flex-shrink: 0;
    .sui-radio__check {
      margin-right: 4px;
    }
  }
  // .extra-desc {
  //   display: block;
  //   width: 100%;
  //   flex-shrink: 0;
  //   margin-top: 7/37.5rem;
  //   .font-dpr(24px);
  //   color: #666;
  // }
}
</style>
