import PWA_LANG from './pwa'
import PC_LANG from './pc'

const LANGS_MAP = {
  pwa: PWA_LANG,
  pc: PC_LANG,
}

export default LANGS_MAP

export type LangProjectTypes = keyof typeof LANGS_MAP

export type PwaLangKeys = keyof typeof LANGS_MAP.pwa
export type PcLangKeys = keyof typeof LANGS_MAP.pc

export type LangKeys = PwaLangKeys | PcLangKeys
