<template>
  <div class="pay-list-block">
    <div
      v-for="item in nowTokenList"
      :key="item.id"
      class="card-block"
      :class="[
        {
          'card-block__disabled': 'isGray' in item,
        },
      ]"
    >
      <div class="card-block-content">
        <div class="item-left">
          <SRadio
            v-model="nowRadio"
            class="payment-item-radio"
            :value="item.id"
            :label="item.id"
            :disabled="'isGray' in item"
            @change="handlerCheck(item)"
          />
        </div>
        <div class="item-right">
          <div class="option-item">
            <div class="card-img-wrap">
              <img
                v-if="item.co_brand_card_tag === '1' && item.tokenCoBrandedLogoUrl"
                class="card-img-bg"
                :src="item.tokenCoBrandedLogoUrl" />
              <template v-else>
                <img
                  class="card-img-bg"
                  :src="defaultCardBg" />
                <img
                  class="card-img-logo"
                  :src="item.web_logo" />
                <span class="card-number">{{ item.card_no }}</span>
              </template>

              <div
                v-if="'isGray' in item"
                class="img-gray"></div>
            </div>
            <div class="card-detail-wrap">
              <span class="card-detail-number">{{ item.card_no }}</span>
            </div>
            <Icon
              name="sui_icon_delete_18px"
              size="18px"
              :is-rotate="cssRight"
              @click="handleDel(item)"
            />
          </div>

          <div
            v-if="'isGray' in item"
            class="disabled-tip">
            <Icon
              name="sui_icon_caution_12px_2"
              size="12px"
              :is-rotate="cssRight"
            />
            <span>
              {{ language.BS_KEY_PAY_1015 }}
            </span>
          </div>
        </div>
      </div>

      <div class="all-discount">
        <DiscountBlock
          :payments-preferential-tips="item.paymentsPreferentialTips"
          :now-pay-bin="item.card_bin"
          :payment-item="nowTokenPay"
          :is-token-list="true"
          :now-use-token-info="nowUseTokenInfo"
          @click-tip="handleShowCardBinArticle"
        />
      </div>
    </div>

    <DeleteTokenDialog
      :language="language"
      :is-show-del-dialog="isShowDelDialog"
      :delete-item="deleteItem"
      @change-token="changeToken"
      @close-delete-token-dialog="closeDeleteTokenDialog"
    />
  </div>
</template>

<script name="TokenList" setup lang="ts">
/**
 * 卡 token 列表选择组件
 */
import { ref, computed, watch } from 'vue'
import type { PaymentItemProps } from '../../../../types/props'
import { Radio as SRadio } from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'
import DiscountBlock from '../cell/DiscountBlock.vue'
import DeleteTokenDialog from './DeleteTokenDialog.vue'
import { usePaymentsStore } from '../../../../hooks/usePayments'
import { useSelectedToken, useGetDefaultToken } from '../../../../hooks/useToken'
import { useGetInstallments } from '../../../../hooks/useGetInstallments'
import { Trade_PayToolKit } from '@shein-aidc/types-trade'
import { LIST_CONTENT_CHANGE_TYPE } from '../../../../types/payments'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { CO_BRAND_CARD_TIPS_TYPE } from '../../../../common/constants'

const defaultCardBg =
  'https://img.ltwebstatic.com/images3_ccc/2024/08/19/07/17240718267e2655926e3ad49b6fad789453fc453b.png'

type cardTokenListType = PaymentItemProps['card_token_list']

interface ChooseTokenDrawerProps {
  language: Record<string, string>
  paymentList: PaymentItemProps['payments']
  isShow: boolean
}

const props = withDefaults(defineProps<ChooseTokenDrawerProps>(), {
  language: () => ({}),
  paymentList: () => [],
  isShow: false,
})

const { cssRight } = useAppConfigs()?.$envs || {}

const paymentState = usePaymentsStore()

const { handleSelectedToken } = useSelectedToken({ paymentStore: paymentState })
const { getInstallments, changePayStoreInstallments } = useGetInstallments({ paymentStore: paymentState })

const emits = defineEmits(['close'])

const nowRadio = ref('')
const isShowDelDialog = ref(false)
const deleteItem = ref({} as Record<string, string>)
const nowTokenList = ref([] as cardTokenListType)

const nowTokenPay = computed(() => {
  return props?.paymentList?.find(item => item?.code === paymentState?.showTokenDrawerPay) as Trade_PayToolKit.PaymentInfoItem
})
const tokenList = computed(() => {
  const _tokenList:cardTokenListType = nowTokenPay.value?.card_token_list || []
  return _tokenList.map(item=>{
    const paymentsPreferentialTips = nowTokenPay.value?.paymentsPreferentialTips || []
    const isCoBrandedCard = item?.co_brand_card_tag === '1'
    return {
      ...item,
      // 不是联名卡的卡bin，过滤掉联名卡权益
      paymentsPreferentialTips:paymentsPreferentialTips.filter(tips=>{
        switch (tips.type) {
          case CO_BRAND_CARD_TIPS_TYPE:
            return isCoBrandedCard
          default:
            return true
        }
      }),
    }
  })
})

const nowUseTokenInfo = computed(() => {
  return nowTokenList.value?.find(item => item.id === nowRadio.value)
})

watch(() => tokenList.value, (val) => {
    nowTokenList.value = val
}, {
    immediate: true,
})

watch(() => props.isShow, () => {
    const defaultData = useGetDefaultToken(paymentState)
    nowRadio.value = defaultData?.id
}, { immediate: true })

const changeToken = () => {
    nowTokenList.value = nowTokenList.value.filter(item => item.id !== deleteItem.value.id)
    paymentState.paymentEventBus?.listContentChange?.emit?.({
      type: LIST_CONTENT_CHANGE_TYPE.DELETE_TOKEN,
      info: deleteItem.value,
    })

    if (nowTokenList.value?.length === 0) {
      handleSelectedToken({
        nowPay: nowTokenPay.value,
        tokenItem: {},
        extraInfo: {
          isNeedPostCheckout: true,
        },
      })
      emits('close')
    }

    paymentState?.paymentEventBus?.paymentInfoChange.emit({
      extraInfo: {
        isNeedPostCheckout: true,
      },
    })
}
const closeDeleteTokenDialog = () => {
    isShowDelDialog.value = false
}

const handleDel = (item) => {
    deleteItem.value = item
    isShowDelDialog.value = true
}

const handlerCheck = (item) => {
  if (nowTokenPay.value?.code === 'routepay-cardinstallment') {
      const useGetInstallmentsParams = {
        paymentCode: nowTokenPay.value?.code || '',
        cardBin: item?.card_bin || '',
        cardType: item.card_type || '',
        cb: (useGetInstallmentsInfo) => {
          if (useGetInstallmentsInfo?.data?.code == 0) {
            const { info = {} } = useGetInstallmentsInfo?.data || {}
            changePayStoreInstallments({
              pay: nowTokenPay.value,
              installments: info || {},
            })
          }
        },
      }
      getInstallments(useGetInstallmentsParams)
    }
    handleSelectedToken({
      tokenItem: item,
      extraInfo: {
        isNeedPostCheckout: true,
      },
    })
    emits('close')
}

const handleShowCardBinArticle = (articleId: string) => {
  // 如果是链接，直接跳转
  const linkReg = /^https?:\/\//
  if (linkReg.test(articleId)) {
    location.href = articleId
    return
  }

  paymentState?.updateState('cardBinArticleInfo', {
    visible: true,
    articleId,
  })
}
</script>

<style lang="less" scoped>
// flex布局
.flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: flex;
  display: -ms-flexbox;
}
// flex 垂直居中
.align-center() {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@zindex-out: 2;
.font-dpr(@font-size) {
  font-size: @font-size * 0.5;
}
// flex 水平居中
.pack-center() {
  -webkit-justify-content: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.payment-item-radio {
  margin-right: 28/75rem;
}
.card-block {
  display: flex;
  flex-direction: column;
  padding: 12 * 2/75rem 12 * 2/75rem 0 12 * 2/75rem;
}

.card-block-content {
  display: flex;
}
.card-block:last-child {
  padding-bottom: 12 * 2/75rem;
}
.option-item {
  width: 100%;
  .flexbox();
  .align-center();
}
.card-img-wrap {
  position: relative;
  z-index: @zindex-out;
  width: 94 * 2/75rem;
  img.card-img-bg {
    width: 100%;
    border-radius: 12/75rem;
  }
  .card-img-logo {
    position: absolute;
    right: 14/75rem;
    top: 14/75rem;
    width: 36/75rem;
    z-index: @zindex-out + 1;
  }
  .card-number {
    position: absolute;
    z-index: @zindex-out + 1;
    text-align: left;
    bottom: 12/75rem;
    left: 12/75rem;
    .font-dpr(24px);
    color: #fff;
    line-height: 1.2 * 0.66666;
    transform: scale(0.6666);
    -webkit-transform: scale(0.6666);
    transform-origin: left bottom;
    -webkit-transform-origin: left bottom;
  }

  .img-gray {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 94 * 2/75rem;
    height: 100%;
    background: linear-gradient(251deg, #ababab 0%, #8f8f8f 100%);
    border-radius: 4.06px;
    opacity: 0.9;
  }
}
.card-detail-wrap {
  flex: 1;
  .flexbox();
  .pack-center();
  flex-direction: column;
  margin-left: 16/75rem;
  overflow: hidden;
  line-height: 1.2;
  .font-dpr(24px);
  .card-detail-type {
    font-weight: 700;
    padding-bottom: 16/75rem;
    font-size: 16px;
  }
  .card-detail-number {
    word-break: break-all;
    color: black;
    font-size: 14px;
    font-family: SF UI Text;
    font-weight: 400;
    word-wrap: break-word;
  }
}

.option-item-icon {
  padding-left: 12/75rem;
  .font-dpr(32px);
  color: #222222;
}

.card-block__disabled {
  .card-detail-number {
    color: #767676;
  }

  .option-item-icon {
    color: #767676;
  }
}

.item-left {
  padding-top: 50/75rem;
}

.item-right {
  width: 100%;
}

.pay-list-block {
  background-color: white;
}

.all-discount {
  padding-left: 70/75rem;
  margin-top: 8/75rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
</style>
