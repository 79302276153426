<template>
  <div
    class="payment-select-bank"
    @click.stop.prevent="emits('click')">
    <ClientOnly>
      <div
        v-if="!isHadSelectedBank"
        class="select-text"
      >{{ text }}</div>
    </ClientOnly>
    <Icon
      name="sui_icon_more_down_16px_2"
      size="16px"
      :is-rotate="cssRight"
    />
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@shein-aidc/icon-vue2'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import ClientOnly from 'vue-client-only'
import { watch } from 'vue'

interface ItemSelectBankProps {
  text: string;
  isHadSelectedBank: boolean;
}

const props = withDefaults(defineProps<ItemSelectBankProps>(), {
  text: '',
  isHadSelectedBank: false,
})

const emits = defineEmits(['click'])
const { cssRight } = useAppConfigs()?.$envs || {}

watch(() => props.isHadSelectedBank, () => {
  console.log('props.isHadSelectedBank >>>>', props.isHadSelectedBank)
}, { immediate: true})
</script>

<style scoped lang="less">
.payment-select-bank {
  display: inline-flex;
  align-items: center;
}
.select-text {
  word-break: break-word;
  font-size: 12px;
  flex: 1;
  margin-right: 0.0533rem;
  color: #666;
}
</style>
