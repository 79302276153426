<template>
  <!-- cvv 提示弹窗 -->
  <SDialog
    :visible.sync="isShowCvvTip"
    :show-close="true"
    :append-to-body="true"
    @close="closeCvvTip"
  >
    <div class="cvv-tip-content">
      <p tabindex="0">
        {{ language.BS_KEY_PAY_1139 }}
      </p>
      <img
        src="https://img.ltwebstatic.com/images3_ccc/2024/09/02/1b/1725283012c8cbc85e7c69d0b70f8b265c06875e28.jpg"
        aria-hidden="true"
      />
    </div>
    <template #footer>
      <div></div>
    </template>
  </SDialog>
</template>

<script name="CvvTipsPopup" setup lang="ts">
/*
 * 支付方式组件: cvv提示弹窗
 *
 * */

import { Dialog as SDialog } from '@shein/sui-mobile'

// utils
import { computed, defineEmits } from 'vue'
// import { type LangKeys } from '../../../common/languages'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  language: {
    type: Object,
    default: () => {},
  },
})

const isShowCvvTip = computed({
  get: () => props.visible,
  set: (val) => emit('update:visible', val),
})

const emit = defineEmits(['update:visible'])

const closeCvvTip = () => {
  isShowCvvTip.value = false
}

</script>

<style lang="less" scoped>
.cvv-tip-content {
  text-align: center;
  p {
    color: #999;
  }
  img {
    width: 100%;
    height: 100%;
    margin: 0;
  }
}
</style>
