import type { DS_PayToolkit, C_PayToolkit } from '../../../../types'

export const fetchPrePayRoutingForCheckoutApi: DS_PayToolkit.APIS_THIS['fetchPrePayRoutingForCheckoutApi'] = async function (params) {
  const result = await this.$schttp({
    baseURL: this.$envs?.langPath,
    url: '/api/pay/prePay/routingForCheckout/query',
    headers: {
      AppCurrency: this.$envs.currency,
      AppLanguage: this.$envs.appLanguage,
    },
    method: 'POST',
    data: params,
  })
  return result.data
}

export const fetchInstallmentInfoApi: DS_PayToolkit.APIS_THIS['fetchInstallmentInfoApi'] = async function (params) {
  const result = await this.$schttp({
    baseURL: this.$envs?.langPath,
    url: '/api/checkout/installmentInfo/get',
    headers: {
      AppCurrency: this.$envs.currency,
      AppLanguage: this.$envs.appLanguage,
    },
    method: 'GET',
    params,
  })
  return result.data
}


// 获取ddc流程表单提交的参数
export const queryPrePayDdcParamsApi: DS_PayToolkit.APIS_THIS['queryPrePayDdcParamsApi'] = async function (params) {
  const result = await this.$schttp({
    baseURL: this.$envs?.langPath,
    url: '/api/pay/prePay/memberPreCardToken/query',
    headers: {
      AppCurrency: this.$envs.currency,
      AppLanguage: this.$envs.appLanguage,
    },
    method: 'POST',
    data: params,
  })
  return result.data
}

// 单后前置路由信息
export const queryUnPayPreRoutingInfoApi: DS_PayToolkit.APIS_THIS['queryUnPayPreRoutingInfoApi'] = async function (data) {
  const result = await this.$schttp({
    baseURL: this.$envs?.langPath,
    method: 'post',
    url: '/ltspc/api/pay/preRoutingInfo/query',
    headers: {
      AppCurrency: this.$envs.currency,
      AppLanguage: this.$envs.appLanguage,
    },
    data,
  })
  return result.data
}

// 获取支付logo /order/get_payment_page_logo
export const getPaymentPageLogoApi: DS_PayToolkit.APIS_THIS['getPaymentPageLogoApi'] = async function (data) {
  const result = await this.$schttp<C_PayToolkit.BaseApiRes<{ logoList: { url: string; sort: number; id: number }[] }>>({
    method: 'get',
    url: '/order/get_payment_page_logo',
    headers: {
      AppCurrency: this.$envs.currency,
      AppLanguage: this.$envs.appLanguage,
    },
    params: data,
  })
  return result.data
}
