<template>
  <div class="payment-card-field">
    <BaseField
      ref="baseFieldRef"
      v-model.trim="modelValue"
      inputmode="text"
      :placeholder="language.BS_KEY_PAY_1168"
      :label="language.BS_KEY_PAY_1169"
      :required="true"
      :show-clear="true"
      :maxlength="maxLength"
      @change="handleValueInput"
      @blur="handleValueBlur"
    />
    <div
      v-show="errorTips"
      class="error-invalid"
    >
      {{ errorTips }}
    </div>
  </div>
</template>

<script setup lang="ts" name="CardNameField">
import { ref } from 'vue'
import BaseField from './BaseField.vue'
import { cardNameValidator } from '../../../../utils/validator'

const emits = defineEmits(['input'])
const props = defineProps({
  value: {
    type: String,
    default: '',
  },
  language: {
    type: Object,
    default: () => ({}),
  },
  required: {
    type: Boolean,
    default: false,
  },
})

const baseFieldRef = ref()
const modelValue = ref('')
const errorTips = ref('')
const maxLength = ref(50)

const handleValueInput = ev => {
  let txt = ev.target.value || ''
  modelValue.value = ev.target.value = txt.trim()
  emits('input', modelValue.value)
}

const handleValueBlur = () => {
  validator()
  emits('input', props?.required ? modelValue.value : '')
}

const validator = () => {
  if (!props?.required) {
    errorTips.value = ''
    return {
      result: true,
      failType: null,
      failReason: null,
    }
  }
  const cardName = modelValue.value?.replace?.(/\s/g, '') || ''
  const valid = cardNameValidator({ cardName })
  errorTips.value = !valid ? '' : props.language.BS_KEY_PAY_1170
  return {
    result: !valid,
    failType: valid,
    failReason: null,
    reasonType: 'card_name',
  }
}

const reset = () => {
  modelValue.value = ''
  errorTips.value = ''
}

defineExpose({
  validator,
  reset,
  focus: () => baseFieldRef.value.focus(),
})

</script>

<style lang="less" scoped>
.font-dpr(@font-size) {
    font-size: @font-size * 0.5;
}

.payment-card-field {
  padding: 24/75rem;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 24/75rem;
    right: 0;
    height: 1px;
    background-color: var(--sui_color_gray_light2, #e5e5e5);
  }
  &.no-underline {
    &:after {
      display: none;
    }
  }
  .error-invalid {
    font-size: 12px;
    line-height: 1;
    color: var(--sui_color_promo, #FA6338);
    margin-top: 8/75rem;
    transition: height 0.8s;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
