<template>
  <div
    v-expose="{
      id: 'expose_payment_method.comp_pay-toolkit',
      data: viewMoreAnalysis
    }"
    class="view-more-payments"
    @click.stop="handleClick"
  >
    <p class="fold-text">
      {{ language.BS_KEY_PAY_1100 }}
    </p>
    <div class="fold-icon">
      <ul>
        <!-- 在大部分情况下最多只能展示7-8个icon，这里简单处理，取最大7个展示 -->
        <li
          v-for="(icon, index) in foldIconList"
          :key="`${icon}-${index}`"
          class="fold-icon-item"
        >
          <img :src="transformImg({ img: icon })" />
        </li>
        <li
          v-if="exceedsIconList"
          class="fold-icon-item fold-icon-more"
        >
          <Icon
            name="sui_icon_more_20px"
            :is-rotate="cssRight"
          />
        </li>
      </ul>
    </div>
    <Icon
      name="sui_icon_more_down_12px_2"
      :is-rotate="cssRight"
    />
  </div>
</template>

<script name="ViewMore" setup lang="ts">
import { computed, inject } from 'vue'
import { transformImg } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue2'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import type { AS_PayToolkit } from '../../../../types'
const { vExpose, triggerNotice } = inject('analysisInstance') as AS_PayToolkit.AnalysisInstance
/**
 * ViewMore组件
 */

interface ViewMoreProps {
  language: Record<string, string>;
  iconList: string[];
  foldedPosition: number;
}

const props = withDefaults(defineProps<ViewMoreProps>(), {
  language: () => ({}),
  iconList: () => [],
  foldedPosition: 0,
})

const emits = defineEmits(['click'])

const { cssRight } = useAppConfigs()?.$envs || {}

const foldIconList = computed(() => {
  return [...(props.iconList || [])].slice(0, 7)
})
const exceedsIconList = computed(() => props.iconList?.length > 8)

const viewMoreAnalysis = computed(() => ({
  payment_method: 'view_more',
  sequence: (props.foldedPosition || 0) + 1,
}))

const handleClick = () => {
  triggerNotice?.({ id: 'click_payment_method.comp_pay-toolkit', data: viewMoreAnalysis.value })
  emits('click')
}


</script>

<style lang="less" scoped>
// .font-dpr(@font-size) {
//     font-size: @font-size * 0.5;
// }

// .border-dpr(@border,@num,@color) {
//     @{border}: @num * 0.5 solid @color;
// }

.view-more-payments {
  padding: 20/75rem 0 20/75rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: @sui_color_gray_dark3;
}

.fold-text {
  flex-shrink: 0;
  margin-right: 4/75rem;
  font-family: "SF Pro";
  font-weight: 400;
}
.fold-icon {
  overflow: hidden;
  margin: 0 8/75rem 0 12/75rem;

  > ul {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .fold-icon-item {
    width: .64rem;
    height: 32/75rem;
    display: inline-block;
    margin-left: 8/75rem;
    flex-shrink: 0;

    &:first-child {
      margin-left: 0;
    }

    > img {
      width: 100%;
      height: 100%;
      vertical-align: baseline;
    }
  }
  .fold-icon-more {
    background-color: @sui_color_gray_weak1;
    border-radius: 4/75rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}

</style>
