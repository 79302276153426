<template>
  <SDrawer
    :visible.sync="visibleDrawer"
    custom-class="pre-pay-date__drawer"
    append-to-body
    :direction="'btt'"
    @open="handleOpenedDrawer"
    @close="handleCloseDrawer"
  >
    <template #topL>
      <span @click="visibleDrawer = false">
        {{ cancelText }}
      </span>
    </template>
    <template #topR>
      <span @click="handleConfirmSelect">
        {{ confirmText }}
      </span>
    </template>
    <SPicker
      ref="pickerRef"
      :columns="dateColumns"
      :defaultIndex="currentDefaultIndex"
      @change="handlePickertChange"
    />
  </SDrawer>
</template>

<script setup lang="ts" name="DatePicker">
import { Drawer as SDrawer, Picker as SPicker } from '@shein/sui-mobile'
import { computed, ref, nextTick } from 'vue'
import { isNull } from '@shein/common-function'

function getYearRange([start, end]) {
  let sd = new Date(),
      ed = new Date()
  sd.setFullYear(sd.getFullYear() + start)
  ed.setFullYear(ed.getFullYear() + end)
  const sy = sd.getFullYear()
  const ey = ed.getFullYear()
  return Array.from({ length: ey - sy + 1 }, (_, i) => sy + i)
}

function getCurrentDate() {
  const nd = new Date()
  return {
    year: nd.getFullYear(),
    month: nd.getMonth() + 1,
    day: nd.getDay() + 1,
  }
}

// const columns =  getYearRange([0, 15]).map((year, index) => ({
//   value: year,
//   index,
//   children: Array.from({ length: 12 }, (_, i) => ({ value: `${i < 9 ? 0 : ''}${i + 1}`, index: i }))
// }))

// const columns = Array.from({ length: 12 }, (_, i) => _ + 1).map((month, index) => ({
//   value: month,
//   index,
//   children:  Array.from({ length: 12 }, _ => _ + 2023).map((year, index) => ({ value: year, index }))
// }))

// const defaultColumns = Array.from({ length: 12 }, (_, i) => i + 1).map((month, index) => ({
//   value: `${month < 10 ? 0 : ''}${month}`,
//   index,
//   children: getYearRange([0, 20]).map((year, index) => ({ value: year, index }))
// }))

const defaultColumns = [
  Array.from({ length: 12 }, (_, i) => i + 1).map((month, index) => ({
    value: `${month < 10 ? 0 : ''}${month}`,
    index,
  })),
  getYearRange([0, 20]).map((year, index) => ({ value: year, index })),
]

const dateColumns = ref(defaultColumns)

function matchIndexByValue([month, year], columns = []) {
  if (isNull(year) || isNull(month)) return []
  try {
    const [monthColumn, yearColumn] = columns || []
    const [defaultMonthIndex, defaultYearIndex] = getDefaultIndex()
    return [monthColumn.find(v => +v.value === +month)?.index || defaultMonthIndex, yearColumn.find(v => +v.value === +year)?.index || defaultYearIndex]
    // const { index, children } = columns.find(v => +v.value == month) || {}
    // const y = (children.find(v => +v.value === year) || {}).index || 0
    // return [index || 0, y]
  } catch (e) {
    return [0, 3]
  }
}

function getDefaultIndex() {
  // const { year } = getCurrentDate()
  // return matchIndexByValue([1, year + 3], defaultColumns)
  return [0, 3]
}

const props = defineProps({
  value: {
    type: Array,
    default: () => [],
  },
  visible: {
    type: Boolean,
    default: false,
  },
  cancelText: {
    type: String,
    default: '',
  },
  confirmText: {
    type: String,
    default: '',
  },
})

const emits = defineEmits(['update:visible', 'select'])
const pickerRef = ref()
const currentDefaultIndex = ref(getDefaultIndex())
// const currentDefaultIndex = ref([0, 0])

const visibleDrawer = computed({
  get () {
    return props.visible
  },
  set (bool) {
    emits('update:visible', !!bool)
  },
})

const handleCloseDrawer = () => {
  visibleDrawer.value = false
}

const handleOpenedDrawer = () => {
  const patchIndexs = matchIndexByValue(props.value, defaultColumns)
  currentDefaultIndex.value = patchIndexs.length ? patchIndexs : currentDefaultIndex.value
  nextTick(() => {
    // currentDefaultIndex.value = patchIndexs.length ? patchIndexs : currentDefaultIndex.value
    if (pickerRef.value) {
      pickerRef.value?.setSelected?.(currentDefaultIndex.value)
    }
  })
}

const handleConfirmSelect = () => {
  visibleDrawer.value = false
  const selectedArr = pickerRef.value.getSelected()
  // console.log('pickerRef.value.getSelected()', pickerRef.value.getSelected(), selectedArr.map(v => +v.value))
  emits('select', selectedArr.map(v => +v.value))
}

const handlePickertChange = (pickerColumns) => {
  const [monthColumn = {}, yearColumn = {}] = pickerColumns || []
  const { year, month } = getCurrentDate()
  // const matchIndexs = matchIndexByValue([month, year], defaultColumns)
  if (+monthColumn.value < +month && +yearColumn.value == +year) {
    // dateColumns.value = defaultColumns.filter(v => +v.index >= matchIndexs[0])
    // pickerRef.value.setColumn(0, defaultColumns[0].filter(v => +v.index >= matchIndexs[0]))
    // nextTick(() => {
    //   console.log('setColumnSelected===', pickerColumns, matchIndexs[0])
    //   pickerRef.value?.setColumnSelected?.(0, matchIndexs[0])
    // })
  } else {
    // pickerRef.value.setColumn(0, defaultColumns[0])
    // dateColumns.value = defaultColumns
  }
}

</script>

<style lang="less" scoped>
.pre-pay-date__drawer {
  .S-picker-column__item {
    font-weight: 700;
  }
}
</style>
