<template>
  <div>
    <iframe
      id="DDC"
      height="1"
      width="1"
      name="DDC"
      style="display: none"
    ></iframe>
    <form
      id="collectionForm"
      method="POST"
      target="DDC"
      style="display: none"
    >
      <input
        type="hidden"
        name="Bin"
      />
      <input
        type="hidden"
        name="JWT"
      />
    </form>
  </div>
</template>

<script name="GetDdcModule" setup lang="ts">
import { watch } from 'vue'
import { debuggerLog } from '../../../../utils'

const props = withDefaults(defineProps<{
  action: string;
  cardBin: string;
  jwt: string;
}>(), {
  action: '',
  cardBin: '',
  jwt: '',
})

watch([
  () => props.action,
  () => props.jwt,
  () => props.cardBin,
], () => {
  (document.getElementById('collectionForm') as any).action = props.action
  // @ts-ignore
  document.querySelector('#collectionForm input[name=\'Bin\']').value = props.cardBin
  // @ts-ignore
  document.querySelector('#collectionForm input[name=\'JWT\']').value = props.jwt
  debuggerLog('DDC--=====', props.action, props.jwt, props.cardBin)
})


const submit = () => {
  (document.getElementById('collectionForm') as any)?.submit?.()
}

defineExpose({
  submit,
})

</script>
