<template>
  <div class="payment-items-container">
    <PaymentItem
      v-for="(item, index) in paymentList"
      ref="paymentItemRef"
      :key="item.id"
      :payment-item="item"
      :sign-up-info="signUpInfo[item.code]"
      :is-selected="selectedPayment.code === item.code"
      :is-folded="index > foldedPositionIndex"
      :payment-index="index"
      :language="language"
      :paypal-message-show="paypalMessageShow"
      :total-price-amount="totalPriceAmount"
      @editable-info-change="handleUpdateEditableInfo"
      @select="handleSelect"
    />
    <ViewMore
      v-if="!isClickViewMore && foldPositionPayments.length"
      :language="language"
      :icon-list="viewMoreIconList"
      :folded-position="paymentItemsConfig?.foldedPosition"
      @click="handleClickViewMore"
    />

    <ClientOnly>
      <LazyMount>
        <CardBinDiscountArticle
          :visible.sync="cardBinArticleInfo.visible"
          :article-id="cardBinArticleInfo.articleId"
          @change-visible="changeArticleVisible"
        />
      </LazyMount>
    </ClientOnly>
  </div>
</template>

<script name="MainPayments" setup lang="ts">
import { provide, computed, defineAsyncComponent } from 'vue'
import { usePaymentItems } from '../../../hooks/usePaymentItems'
import PaymentItem from './cell/PaymentItem.vue'
import ViewMore from './cell/ViewMore.vue'
import type { PaymentItemSelectType, PaymentItemsConfig } from '../../../types'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import { usePaymentsStore } from '../../../hooks/usePayments'
import { usePaypalBnplMessage } from '../../../hooks/usePaypalBnplMessage'
import { useAnalysis } from '../../../common/analysisSource'
import ClientOnly from 'vue-client-only'
import { LazyMount } from '@shein/sui-mobile'
const analysisInstance = useAnalysis()
provide('analysisInstance', analysisInstance)

const CardBinDiscountArticle = defineAsyncComponent(() => import('./cell/CardBinDiscountArticle.vue'))

interface PaymentItemsProps {
  language: Record<string, string>;
  payments: Trade_PayToolKit.PaymentInfoItem[];
  selectedPayment: Trade_PayToolKit.SelectedPaymentInfo;
  signUpInfo: Record<string, {
    use_one_time_sign: boolean;
    signupFlag: string;
  }>;
  paymentItemsConfig?: PaymentItemsConfig;
}

const props = withDefaults(defineProps<PaymentItemsProps>(), {
  payments: () => ([]),
  language: () => ({}),
  selectedPayment: () => ({} as Trade_PayToolKit.SelectedPaymentInfo),
  signUpInfo: () => ({}),
  paymentItemsConfig: () => ({}),
})

const emit = defineEmits<{
  (event: 'select', options: { type: PaymentItemSelectType; paymentItem: Trade_PayToolKit.PaymentInfoItem }): void
  (event: 'editable-info-change', info: { [key: Trade_PayToolKit.PAYMENT_CODE_KEY]: Trade_PayToolKit.EditablePaymentItem }): void
}>()

const paymentsStore = usePaymentsStore()

const {
  isClickViewMore,
  foldPositionPayments,
  paymentList,
  viewMoreIconList,
  foldedPositionIndex,
  handleClickViewMore,
} = usePaymentItems(props)

const paypalBnplCurrencySupportList = computed(() => props.payments?.find(item => item.code === 'PayPal-bnpl')?.currencySupportList || [])

const cardBinArticleInfo = computed(() => paymentsStore?.cardBinArticleInfo)

const { paypalMessageShow, totalPriceAmount } = usePaypalBnplMessage({
  paymentsStore,
  paypalBnplCurrencySupportList,
})

const handleSelectEvt = ({ type, paymentItem }: { type: PaymentItemSelectType; paymentItem: Trade_PayToolKit.PaymentInfoItem }) => {
  emit('select', { type, paymentItem })
}


const handleSelect = ({ paymentItem }: { paymentItem: Trade_PayToolKit.PaymentInfoItem; }) => {
  handleSelectEvt({ type: 'select-payment', paymentItem })
}

const handleUpdateEditableInfo = ({ key, info }: { key: Trade_PayToolKit.PAYMENT_CODE_KEY, info: Trade_PayToolKit.EditablePaymentItem }) => {
  emit('editable-info-change', { [key]: info })
}

const changeArticleVisible = () => {
  paymentsStore?.updateState('cardBinArticleInfo', {
    visible: false,
    articleId: '',
  })
}
</script>
