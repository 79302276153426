<template>
  <div
    v-if="isShowLoading"
    class="pay-loading"
  >
    <div
      v-if="showOrderLoading === 'process-loading'"
      class="loading-order"
    >
      <img
        class="loading-img-1"
        src="https://img.ltwebstatic.com/images3_ccc/2024/08/02/48/172256713491c23417ec4e0136195de90f41f44981.png"
        alt="loading animation img1"
      />

      <img
        class="loading-img-2"
        src="https://img.ltwebstatic.com/images3_ccc/2024/08/02/72/172256712852b8b40d801e825a26ed725ddd7fc077.png"
        alt="loading animation img2"
      />

      <img
        class="loading-img-3"
        src="https://img.ltwebstatic.com/images3_ccc/2024/08/02/5f/1722567131324e7b444b080b6d667a8935fe4a7d35.png"
        alt="loading animation img3"
      />

      <img
        class="loading-img-4"
        src="https://img.ltwebstatic.com/images3_ccc/2024/08/02/86/1722566311e4e516e113a80cf95faa9f30b22067fa.png"
        alt="loading animation img4"
      />

      <img
        class="loading-img-5"
        src="https://img.ltwebstatic.com/images3_ccc/2024/08/02/53/1722567106bc848db85e501c57b06d9ac6229ac963.png"
        alt="loading animation img5"
      />
    </div>

    <div
      v-if="showOrderLoading === 'success-loading'"
      class="loading-pay"
    >
      <img
        class="loading-img-1"
        src="https://img.ltwebstatic.com/images3_ccc/2024/08/02/c9/172256731518ff805087feb703190f24f458f105eb.png"
        alt="loading animation img1"
        @load="handleImgLoad"
      />

      <img
        class="loading-img-2"
        src="https://img.ltwebstatic.com/images3_ccc/2024/08/02/3a/1722567313df692dbe120109d4896a9302e88ec924.png"
        alt="loading animation img2"
        @load="handleImgLoad"
      />

      <img
        class="loading-img-3"
        src="https://img.ltwebstatic.com/images3_ccc/2024/08/02/06/1722567311585aa92a2447e1d48cffed3169fc4e0b.png"
        alt="loading animation img3"
        @load="handleImgLoad"
      />

      <img
        class="loading-img-4"
        src="https://img.ltwebstatic.com/images3_ccc/2024/08/02/b4/1722567308e66b3f1c60de0ee3e618475b42d39682.png"
        alt="loading animation img4"
        @load="handleImgLoad"
      />

      <img
        class="loading-img-5"
        src="https://img.ltwebstatic.com/images3_ccc/2024/08/02/6b/1722567306678274cecd2f881f9407311406e0c235.png"
        alt="loading animation img5"
        @load="handleImgLoad"
        @animationend="handleAnimationEnd"
      />

      <img
        class="loading-img-6"
        src="https://img.ltwebstatic.com/images3_ccc/2024/10/28/3e/17300852864eb31db72845d4e7ae4a39decdd62d7f.png"
        alt="loading animation img6"
        @load="handleImgLoad"
      />

      <div
        class="loading-img-mask"
      ></div>
    </div>

    <div class="loading-text">
      {{ loadingText }}
    </div>
  </div>
</template>

<script name="CheckoutLoading" setup lang="ts">
/**
 * @file 下单页loading组件
 */
 import { watch, computed, nextTick, ref } from 'vue'
import { useAnalysis } from '../../../common/analysisSource'
const { triggerNotice } = useAnalysis()

interface CheckoutLoadingProps {
  loadingText: string;
  newCheckoutLoadingType: string;
}

const props = withDefaults(defineProps<CheckoutLoadingProps>(), {
  loadingText: '',
  newCheckoutLoadingType: '',
})


const sceneObj = {
  'create-order': 'add_order',
  'order-to-pay': 'submit_payment',
  'pay-success': 'payment_successful',
  'verification-successful': 'payment_successful',
}

// const canUsePay = ref(['routepay-card', 'routepay-cardinstallment', 'cod'])
const loadingType = ref(['create-order', 'order-to-pay', 'pay-success', 'verification-successful'])


const showOrderLoading = ref('')

let payAnimationImgLoaded = 0

const handleAnimationEnd = () => {
  const img5 = document.querySelector('.loading-pay > .loading-img-5')
  const img6 = document.querySelector('.loading-pay > .loading-img-6')
  const mask = document.querySelector('.loading-pay > .loading-img-mask')
  // img5 display none
  img5?.setAttribute('style', 'display: none')
  // img6 display block
  img6?.setAttribute('style', 'display: block')
  mask?.setAttribute('style', 'animation: hide-mask 1s forwards')
}

const handleImgLoad = () => {
  payAnimationImgLoaded++
  if (payAnimationImgLoaded === 6) {
    const img5 = document.querySelector('.loading-pay > .loading-img-5')
    setTimeout(() => {
      img5?.setAttribute('style', 'animation: noop 1s forwards')
    }, 1000)
  }
}

const isShowLoading = computed(() => {
  // if (props.newCheckoutLoadingType !== 'create-order') {
  //   if (!canUsePay.value.includes(props.nowPay)) return false
  //   if (props.nowPay === 'cod' && !props.isCodPopup) return false
  // }
  return loadingType.value.includes(props.newCheckoutLoadingType)
})

const scene = computed(() => {
  return sceneObj[props.newCheckoutLoadingType] || ''
})

watch(
  () => props.newCheckoutLoadingType,
  val => {
    if (val) {
      initAnimation()
    } else {
      showOrderLoading.value = ''
      payAnimationImgLoaded = 0
    }
  },
  {
    immediate: true,
  },
)

watch(
  () => props.newCheckoutLoadingType,
  val => {
    if (val && isShowLoading.value) {
      triggerNotice({
        id: 'expose_safety_flow.comp_pay_loading',
        data: {
          expose_scence: scene.value,
        },
      })
    }
  }, {
    immediate: true,
  },
)

function initAnimation() {
  nextTick(() => {
    const nowUseImgObj = {
      'create-order': 'process-loading',
      'order-to-pay': 'process-loading',
      'pay-success': 'success-loading',
      'verification-successful': 'success-loading',
    }

    showOrderLoading.value = nowUseImgObj[props.newCheckoutLoadingType]
  })
}
</script>
<style lang="less">
.pay-loading {
  width: 195 * 2/75rem;
  height: 175 * 2/74rem;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 16 * 2/75rem;
  position: fixed; /* 固定定位 */
  top: 50%; /* 距离顶端 50% */
  left: 50%; /* 距离左端 50% */
  transform: translate(-50%, -50%); /* 通过变换将元素拉回自己的中心 */
  z-index: 99999999;

  .loading-text {
    color: white;
    font-size: 14px;
    font-family: SF Pro;
    font-weight: 510;
    word-wrap: break-word;
    // 超出一行展示省略号
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    position: absolute;
    bottom: 24 * 2/75rem;
    left: 0;
    right: 0;
  }

  .loading-order {
    position: relative;
  }

  .loading-pay {
    position: relative;
  }

  .loading-img-1 {
    width: 100%;
  }
  .loading-img-2 {
    position: absolute;
    width: 66 * 2/75rem;
    left: 50%;
    transform: translateX(-50%);
    top: 34 * 2/75rem;
  }
  .loading-img-3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 68 * 2/75rem;
    opacity: 0;
    animation: checkout-shining 1s linear infinite;
  }
  .loading-img-4 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 118.5 * 2/75rem;
    top: 9 * 2/75rem;
    animation: checkout-rotate 1s linear infinite;
  }
  .loading-img-5 {
    position: absolute;
    width: 152.1 * 2/75rem;
    left: 50%;
    transform: translateX(-50%);
    top: 6 * 2/75rem;
  }

  .loading-img-6 {
    position: absolute;
    width: 19.5 * 2/75rem;
    left: 50%;
    transform: translateX(-50%);
    top: 60 * 2/75rem;
    display: none;
  }

  .loading-img-mask {
    position: absolute;
    width: 21 * 2/75rem;
    height: 16 * 2/75rem;
    right: calc(50% - 10px);
    top: 58 * 2/75rem;
    background-color: #fff;
    opacity: 0;
  }

  @keyframes checkout-rotate {
    0% {
      transform: translateX(-50%) rotate(0deg);
    }
    100% {
      transform: translateX(-50%) rotate(360deg);
    }
  }

  @keyframes checkout-shining {
    0% {
      opacity: 0;
      top: -2 * 2/75rem;
      left: 32 * 2/75rem;
    }

    50% {
      opacity: 0.2;
    }

    100% {
      opacity: 0;
      top: 66 * 2/75rem;
      left: 108 * 2/75rem;
    }
  }

  @keyframes hide-mask {
    0% {
      width: 19.5 * 2/75rem;
      opacity: 1;
    }

    100% {
      width: 0;
      opacity: 1;
    }
  }

  @keyframes noop {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }
}
</style>
