<template>
  <SDrawer
    :visible.sync="visibleArticleDrawer"
    type="full"
    direction="rtl"
    append-to-body
  >
    <template #top>
      {{ articleInfo.conName }}
    </template>
    <SLoading
      type="curpage"
      :show="showLoading"
      :container-cover-style="{
        top: '1.17rem'
      }"
    />
    <div
      class="article-content c-pwa-article"
      v-html="articleInfo.conText"
    ></div>
  </SDrawer>
</template>

<script setup name="CardBinDiscountArticle" lang="ts">
import { computed, watch, onMounted } from 'vue'
import { Loading as SLoading, Drawer as SDrawer } from '@shein/sui-mobile'
import useCardBinDiscountArticle from '../../../../hooks/useCardBinDiscountArticle'

const { getArticleInfo, showLoading, articleInfo } = useCardBinDiscountArticle()

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  articleId: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['change-visible', 'closeLoading'])

const visibleArticleDrawer = computed({
  get: () => props.visible,
  set: (val) => emit('change-visible', val),
})

watch(() => props.visible, (val) => {
  if (val) {
    getArticleInfo(props.articleId)
  }
}, { immediate: true })

onMounted(() => {
  emit('closeLoading')
})
</script>

<style lang="less" scoped>
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.font-dpr(@font-size) {
    font-size: @font-size * 0.5;
}

.txt-l() {
    text-align: left;
}

.border-dpr(@border,@num,@color) {
    @{border}: @num * 0.5 solid @color;
}

// flex布局
.flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: flex;
  display: -ms-flexbox;
}

// flex 垂直居中
.align-center() {
  -webkit-align-items: center;
    -webkit-box-align: center;
       -moz-box-align: center;
       -ms-flex-align: center;
          align-items: center;
}

.margin-r(@size) {
    margin-right: @size;
}

.c-pwa-article{
  .c-article-first-order{
      padding: 0;
      background-color: #f6f6f6;
      .a-order-top{
          padding: .64rem .32rem;
          text-align: center;
          background-color: #fff;
          .t1{
              margin-bottom: .32rem;
              .font-dpr(46px);
              font-weight: bold;
              color: #222;
          }
          .t2{
              margin-bottom: .52rem;
              .font-dpr(24px);
              font-weight: bold;
              color: #222;
          }
          .des{
              margin-bottom: .52rem;
          }
          .btn{
              display: inline-block;
              .btn-item{
                  & + .btn-item{
                      margin-top: .52rem;
                  }
              }
              a{
                  width: 100%;
                  padding: 0 .3rem;
                  height: 1.08rem;
                  line-height: 1.08rem;
                  .font-dpr(28px);
                  text-transform: unset;
                  color: #fff;
                  background-color: #222;
                  display: inline-block;
                  text-decoration: none;
              }
          }
      }
      .first-order-item{
          margin-top: .32rem;
          padding: .32rem;
          background-color: #fff;
          .article-con-item{
              margin: 0;
              .item-img{
                  .border-dpr(border, 2px, #222);
              }
          }
          .member-level{
              margin-top: .2rem;
              .l-item{
                  .flexbox();
                  .align-center();
              }
              .img{
                  .margin-r(.44rem);
                  width: 1.2rem;
                  height: 1.08rem;
                  img{
                      width: 100%;
                  }
              }
              .des{
                  padding: .32rem 0;
                  width: 7.72rem;
                  .font-dpr(24px);
                  color: #222;
                  font-weight: bold;
              }
          }
          .buy-step{
              position: relative;
              margin: .32rem 0;
              .num{
                  display: none;
              }
              .s-item{
                  .flexbox();
                  .align-center();
              }
              .img{
                  .margin-r(.32rem);
                  width: 1rem;
                  height: 1rem;
                  img{
                      width: 100%;
                  }
              }
              .des{
                  padding: .32rem 0;
                  width: 8.04rem;
                  border-bottom: 1px solid #e5e5e5;
                  .font-dpr(24px);
                  color: #222;
                  line-height: 1.2;
                  div{
                      display: inline-block;
                  }
                  .t{
                      margin-bottom: 10px;
                      display: block;
                  }

              }
          }
      }
      .first-order-item-se{
          margin-top: .32rem;
          padding: .32rem;
          background-color: #fff;
          text-align: center;
          .se-t{
              margin-bottom: .36rem;
              .font-dpr(24px);
              font-weight: bold;
              color: #222;
          }
          .shop-item{
              .txt-l();
              >a{
                  .margin-r(.8rem);
                  margin-bottom: .52rem;
                  padding: 0 .32rem;
                  height: .64rem;
                  line-height: .64rem;
                  min-width: 2.16rem;
                  color: #fff;
                  background-color: #222;
                  display: inline-block;
                  cursor: pointer;
                  text-decoration: none;

              }

          }
          .offer-con{
              .offer-item{
                  .txt-l();
                  .offer-item-inner{
                      .flexbox();
                      .align-center();
                  }
                  .img{
                      .margin-r(.32rem);
                      img{
                          width: .8rem;
                          height: .8rem;
                      }
                  }
                  .des{
                      padding: .32rem 0;
                      width: 8.24rem;
                      border-bottom: 1px solid #e5e5e5;
                      .font-dpr(24px);
                      .t{
                          color: #222;
                          font-weight: bold;
                          .txt-l();
                      }
                  }
              }
          }
      }
  }
}
</style>
