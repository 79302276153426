<template>
  <SDrawer
    :visible.sync="isShowBnplDrawer"
    :append-to-body="true"
    class="homogenization-drawer"
    @close-from-icon="handleClose"
    @close-from-mask="handleClose"
  >
    <template #top>
      {{ homogenizationPay.title }}
    </template>
    <div class="bnpl-payments-container">
      <PaymentItems
        :payments="payments"
        :language="language"
        :sign-up-info="editablePaymentInfo"
        :selected-payment="temporaryPayment"
        @editable-info-change="handleChangeEvt"
        @select="handleSelectEvt"
      />
    </div>

    <template #footer>
      <SButton
        class="try-btn"
        :width="'100%'"
        :disabled="!isSelected"
        :type="['primary', 'H80PX']"
        @click="confirmSelect"
      >
        {{ language.BS_KEY_PAY_1069 }}
      </SButton>
    </template>
  </SDrawer>
</template>

<script name="BnplPayments" setup lang="ts">
/**
 * 同质化支付方式选择抽屉
 */
import { Drawer as SDrawer, Button as SButton } from '@shein/sui-mobile'
import { inject, computed, ref, watch } from 'vue'
import type { PaymentsStore } from '../../../hooks/usePayments'
import { useSelectedPayment } from '../../../hooks/useSelectedPayment'
import { useEditablePaymentInfo } from '../../../hooks/useEditablePaymentInfo'
import { PAY_TOOLKIT_PAYMENTS_INJECT_KEY } from '../../../common/constants'
import PaymentItems from '../payment-items/PaymentItems.vue'
import type { PaymentItemSelectType } from '../../../types'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import { debuggerLog } from '../../../utils'
import type { AS_PayToolkit } from '../../../types'
const { triggerNotice } = inject('analysisInstance') as AS_PayToolkit.AnalysisInstance

interface BnplPaymentsProps {
  language: Record<string, string>;
  payments: Trade_PayToolKit.PaymentInfoItem[];
  homogenizationPay: Trade_PayToolKit.PaymentInfoItem;
  visible: boolean;
}

const props = withDefaults(defineProps<BnplPaymentsProps>(), {
  payments: () => ([]),
  language: () => ({}),
  homogenizationPay: () => ({} as Trade_PayToolKit.PaymentInfoItem),
})

const emit = defineEmits<{
  (event: 'confirm-select', info: Partial<Trade_PayToolKit.SelectedPaymentInfo>): void
  (event: 'update:visible', info: Boolean): void
}>()

const { handleEditableInfoChange, editablePaymentInfo } = useEditablePaymentInfo()

const { handleSelectedPayment } = useSelectedPayment()
const paymentState = inject<PaymentsStore>(PAY_TOOLKIT_PAYMENTS_INJECT_KEY)

const nowPaymentItem = ref<Partial<Trade_PayToolKit.SelectedPaymentInfo>>({})

const isShowBnplDrawer = computed({
  get: () => props.visible,
  set: (val) => emit('update:visible', val),
})
const bnplInfo = computed(() => paymentState?.bnplInfo || { visibleBnpl: false, payments: [], selectedPayment: {} })
const temporaryPayment = computed(() => paymentState?.temporaryPayment)
const isSelected = computed(() => !!temporaryPayment.value?.code)

const isDefaultPayment = computed(() => {
  return (props.payments || [])?.some(item => item?.last_payment)
})

const handleSelectEvt = ({ type, paymentItem }: { type: PaymentItemSelectType; paymentItem: Trade_PayToolKit.PaymentInfoItem }) => {
  debuggerLog('handleSelectEvt===', { type, paymentItem })
  triggerNotice({
    id: 'click_unfolded_payment_method.comp_pay-toolkit',
    data: {
      cate_two_pay_mtd: paymentItem?.code,
      cate_two_is_default: paymentItem?.last_payment ? 1 : 0,
      is_vaulting: '-',
    },
  })

  if (type === 'select-payment') {
    paymentState?.updateState('temporaryPayment', paymentItem)
    handleSelectedPayment({ item: paymentItem })
  }
  nowPaymentItem.value = Object.assign({}, paymentItem)
}

const handleChangeEvt = (info: { [key: Trade_PayToolKit.PAYMENT_CODE_KEY]: Trade_PayToolKit.EditablePaymentItem }) => {
  handleEditableInfoChange({
    changeInfo: info,
  })
}

const handleClose = () => {
  paymentState?.mergeState('bnplInfo', {
    ...bnplInfo.value,
    visibleBnpl: false,
  })
}

const confirmSelect = () => {
  debuggerLog('confirmSelect===', nowPaymentItem.value, paymentState?.selectedPayment)
  triggerNotice({
    id: 'click_pay_mtd_confirm.comp_pay-toolkit',
    data: {
      cate_two_pay_mtd: nowPaymentItem.value?.code,
      cate_two_is_default: nowPaymentItem.value?.last_payment ? 1 : 0,
    },
  })
  if (Object.keys(nowPaymentItem.value || {}).length === 0 || paymentState?.selectedPayment?.code === nowPaymentItem.value.code) {
    handleClose()
    return
  }
  emit('confirm-select', nowPaymentItem.value)
}

watch(() => isShowBnplDrawer.value, () => {
  if (isShowBnplDrawer.value) {
    (props.payments || [])?.forEach(item => {
      triggerNotice({
        id: 'expose_unfolded_payment_method.comp_pay-toolkit',
        data: {
          cate_two_pay_mtd: item?.code,
          cate_two_is_default: item?.last_payment ? 1 : 0,
          is_vaulting: '-',
        },
      })
    })

    triggerNotice({
        id: 'click_folded_payment_method.comp_pay-toolkit',
        data: {
          cate_one_pay_mtd: props.homogenizationPay?.code || '',
          cate_one_is_default: isDefaultPayment.value ? 1 : 0,
        },
    })
  }
}, {
  immediate: true,
})
</script>

<style lang="less" scoped>
.homogenization-drawer {
  /deep/.S-drawer__normal-footer {
    box-sizing: content-box;
    margin-top: unset;
    padding: 8 * 2/75rem 12 * 2/75rem;
  }

  /deep/.S-alert__icon {
    color: #767676;
  }

  /deep/.S-alert__description {
    color: #222222;
    font-size: 12px;
    font-family: SF UI Text;
    font-weight: 400;
    word-wrap: break-word;
  }
}

.bnpl-payments-container {
  padding: 0 20/75rem;
}
</style>
