<template>
  <div class="payment-card-field no-underline">
    <BaseField
      ref="baseFieldRef"
      v-model.trim="modelValue"
      inputmode="numeric"
      placeholder="000"
      :label="language.BS_KEY_PAY_1167"
      :maxlength="cvvMaxLenth"
      :show-tips="true"
      :required="isRequired"
      @focus="handleFocus"
      @change="handleValueInput"
      @blur="handleValueBlur"
      @click-tips="emits('click-tips')"
    />
    <div
      v-show="errorTips"
      class="error-invalid"
    >
      {{ errorTips }}
    </div>
  </div>
</template>

<script setup name="CardCvvField" lang="ts">
import { ref, computed } from 'vue'
import BaseField from './BaseField.vue'
import { CARD_TYPE_ENUM } from '../../../../common/constants'
import { cvvValidator } from '../../../../utils/validator'

const props = defineProps({
  language: {
    type: Object,
    default: () => {},
  },
  value: {
    type: String,
    default: '',
  },
  cardType: {
    type: String,
    default: '',
  },
})

const emits = defineEmits(['click-tips', 'input'])

const baseFieldRef = ref()
const modelValue = ref('')
const errorTips = ref('')

const lowerCardType = computed(() => props.cardType?.toLowerCase?.() || '')

const cvvMaxLenth = computed(() => !modelValue.value || lowerCardType.value === CARD_TYPE_ENUM.AMEX ? 4 : 3)
const isRequired = computed(() => !!cvvValidator({ cardType: lowerCardType.value, cvv: '' }))

const handleValueInput = ev => {
  let txt = ev.target.value
  //格式化
  let num = txt.replace(/\s/g, '') || ''
  modelValue.value = ev.target.value = num
  emits('input', num)
}

const handleFocus = () => {
  errorTips.value = ''
}

const handleValueBlur = () => {
  validator()
  emits('input', modelValue.value)
}

const validator = () => {
  const cvv = modelValue.value?.replace?.(/\s/g, '') || ''
  const valid = cvvValidator({
    cvv: cvv,
    cardType: lowerCardType.value,
  })
  errorTips.value = !valid ? '' : props.language?.BS_KEY_PAY_1143
  return {
    result: !valid,
    failType: valid,
    failReason: null,
    reasonType: 'cvv',
  }
}

const reset = () => {
  modelValue.value = ''
  errorTips.value = ''
}

defineExpose({
  validator,
  reset,
  focus: () => baseFieldRef.value.focus(),
})

</script>

<style lang="less" scoped>
.font-dpr(@font-size) {
    font-size: @font-size * 0.5;
}

.payment-card-field {
  padding: 24/75rem;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 24/75rem;
    right: 0;
    height: 1px;
    background-color: var(--sui_color_gray_light2, #e5e5e5);
  }
  &.no-underline {
    &:after {
      display: none;
    }
  }
  .error-invalid {
    font-size: 12px;
    line-height: 1;
    color: var(--sui_color_promo, #FA6338);
    margin-top: 8/75rem;
    transition: height 0.8s;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
