import { computed } from 'vue'
import { useViewMore } from './useViewMore'
import type { PaymentItemsProps } from '../types/props'
import { debuggerLog } from '../utils'

export const usePaymentItems = (props: PaymentItemsProps) => {
  debuggerLog('usePaymentItems===props===', props)
  const { isClickViewMore, foldPositionPayments, viewMoreIconList, foldedPositionIndex, handleClickViewMore } = useViewMore(props)

  const paymentList = computed(() => {
    if (!foldedPositionIndex.value || !foldPositionPayments.value?.length) return props.payments
    return props.payments.filter((_, index) => index <= foldedPositionIndex.value)
  })

  return {
    isClickViewMore,
    foldPositionPayments,
    viewMoreIconList,
    handleClickViewMore,
    paymentList,
    foldedPositionIndex,
  }
}
