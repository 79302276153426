import { computed, ref } from 'vue'
import useApis from './useApis'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { usePaymentsStore, type PaymentsStore } from './usePayments'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import { debuggerLog } from '../utils'

const defaultConfig = {
  needCollectEmail: false,
  needCollectPhoneNumber: false,
  paymentCode: '',
  countryCode: '',
  countryId: '',
  emailRule: {
    reg: /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,6}|[0-9]{1,3})(\]?)$/,
    copywrite: '',
  },
  phoneCountryNum: '',
  needContinuePay: false,
  visible: false,
  type: 'dialog',
}

const defaultCapitecDialogData = {
  phone: '',
  phoneError: 0,
  isPhoneChange: false,
  email: '',
  emailError: 0,
  isEmailChange: false,
}

const capitecDialogData = ref<Trade_PayToolKit.ICapitecDialogData>(defaultCapitecDialogData)

export default function useCapitecDialog (paymentStore?: PaymentsStore) {
  const { getTelCountryListApi, checkAliasApi } = useApis()
  const appConfigs = useAppConfigs()
  if (!paymentStore) {
    paymentStore = usePaymentsStore()
  }
  const dialogConfig = computed(() => paymentStore.capitecDialogConfig)
  const addressInfo = computed(() => paymentStore.checkoutInfo?.addressInfo || {})
  const capitecVisible = computed({
    get: () => dialogConfig.value.visible,
    set: (val) => {
      paymentStore.mergeState('capitecDialogConfig', {
        visible: val,
      })
    },
  })

  const fetchUserPaymentRelatedInfo = () => {
    // if (!countryCode || !paymentCode) {
    //   return
    // }
    capitecDialogData.value.email = paymentStore.userInfoGetters.email || ''
    capitecDialogData.value.phone = addressInfo.value?.tel || ''
    debuggerLog('fetchUserPaymentRelatedInfo===??====', paymentStore.checkoutInfo, addressInfo.value, capitecDialogData.value, capitecDialogData.value.phone)
    // try {
    //   const res = await getPaymentRelatedInfoApi({ data: {countryCode, paymentCode}, currency: paymentStore.checkoutInfo.currencyCode, appLanguage: appConfigs.$envs.appLanguage})
    //   capitecDialogData.value.email = paymentStore.userInfoGetters.email || ''
    //   if (res.code === '0' && res.info) {
    //     const { paymentRelatedInfo, phoneCheckRule } = res.info
    //     if (paymentRelatedInfo) {
    //       const { email, phoneNumber } = paymentRelatedInfo
    //       capitecDialogData.value.email = email || paymentStore.userInfoGetters.email || ''
    //       capitecDialogData.value.phone = phoneNumber || ''
    //     }
    //     if (phoneCheckRule) {
    //       const { result } = phoneCheckRule
    //       if (result) {
    //         const { regex_list } = result
    //         if (regex_list && regex_list.length) {
    //           dialogConfig.value.phoneRule = regex_list[0]
    //         }
    //       }
    //     }
    //   }
    // } catch (error) {
    //   capitecDialogData.value.email = paymentStore.userInfoGetters.email || ''
    // }
  }

  const fetchTelCountryList = async ({ countryId }: Pick<Trade_PayToolKit.ICapitecDialogConfig, 'countryId'>) => {
    countryId = countryId || ''
    const res = await getTelCountryListApi({
      appLanguage: appConfigs?.$envs?.appLanguage,
      currency: appConfigs?.$envs?.currency,
    })
    console.log('fetchTelCountryList===??====', res, countryId)
    if (res?.code == '0' && res?.info?.country?.item_cates?.length) {
      const countryInfo = res.info.country.item_cates.find(item => item.id === countryId)

      if (countryInfo) {
        const phoneCountryNum = `${countryInfo.value}+${countryInfo.countrynum}`
        paymentStore.mergeState('capitecDialogConfig', {
          phoneCountryNum,
        })
      }
    }
  }

  const openCapitecDialog = async (config: Partial<Trade_PayToolKit.ICapitecDialogConfig>) => {
    paymentStore.updateState('capitecDialogConfig', {
      ...defaultConfig,
      ...config,
    })
    capitecDialogData.value = defaultCapitecDialogData
    await Promise.all([
      fetchUserPaymentRelatedInfo(),
      fetchTelCountryList({ countryId: config.countryId }),
    ])
    paymentStore.mergeState('capitecDialogConfig', {
      visible: true,
    })
  }

  const closeCapitecDialog = () => {
    paymentStore.mergeState('capitecDialogConfig', {
      visible: false,
    })
  }

  const genReportConfirmReason = () => {
    const reasonArr = new Set()
    const reasonTextArr = ['', 'unfilled', 'incorrect_format']
    if (capitecDialogData.value.phoneError) {
      reasonArr.add(reasonTextArr[capitecDialogData.value.phoneError])
    }
    if (capitecDialogData.value.emailError) {
      reasonArr.add(reasonTextArr[capitecDialogData.value.emailError])
    }
    return [...reasonArr].join()
  }

  const needValidCapitec = () => {
    const selectedPayment = paymentStore.selectedPayment

    const needCollectEmail = selectedPayment.collect_email == '1'
    const needCollectPhone = selectedPayment.collect_phone_number == '1'

    return (needCollectEmail && !selectedPayment?.pay_email) || (needCollectPhone && !selectedPayment?.pay_phone)
  }

  const validateByPay = (): Trade_PayToolKit.ValidateByPayType => {
    const selectedPayment = paymentStore.selectedPayment

    const needCollectEmail = selectedPayment.collect_email == '1'
    const needCollectPhone = selectedPayment.collect_phone_number == '1'

    if (needValidCapitec()) {
      openCapitecDialog({
        countryCode: addressInfo.value?.countryCode || '',
        paymentCode: selectedPayment.code,
        countryId: addressInfo.value.countryId || '',
        needCollectEmail: needCollectEmail && !selectedPayment?.pay_email,
        needCollectPhoneNumber: needCollectPhone && !selectedPayment?.pay_phone,
        needContinuePay: true,
        type: selectedPayment.code === 'adyen-mbway' ? 'drawer' : 'dialog',
      })

      let metric_scene = 'payment_phone_email_empty'
      let result_reason = ''
      if (selectedPayment.code === 'adyen-mbway') {
        metric_scene = 'payment_mbway_phone_empty'
        result_reason = '1_7'
      }
      if (selectedPayment.code?.indexOf?.('boleto') > 0) {
        metric_scene = 'payment_boleto_phone_empty'
        result_reason = '1_8'
      }

      return {
        validateResult: false,
        scene: 'open_capitec_dialog',
        metric_scene,
        saParams: {
          result_reason,
        },
      }
    }
    return {
      validateResult: true,
      scene: '',
    }
  }


  return {
    capitecVisible,
    capitecDialogData,
    dialogConfig,
    genReportConfirmReason,
    checkAliasApi,
    addressInfo,
    openCapitecDialog,
    closeCapitecDialog,
    validateByPay,
    needValidCapitec,
  }
}
