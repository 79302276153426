<template>
  <SButton
    ref="venmoBtnRef"
    :type="buttonType"
    :loading="buttonLoading"
    :width="buttonWidth"
    class="bs-venmo-button"
    @click="emit('click')"
  >
    <img
      v-show="!buttonLoading"
      src="https://shein.ltwebstatic.com/svgicons/2024/08/24/17244964782338039555.svg"
      class="bs-venmo-button__icon"
    />
  </SButton>
  <!-- https://shein.ltwebstatic.com/svgicons/2024/08/24/17244955192992476605.svg -->
</template>

<script name="BPaypalVenmoButton" setup lang="ts">
import { ref, computed, watch } from 'vue'
import { Button as SButton } from '@shein/sui-mobile'

interface BPaypalButtonProps {
  buttonType?: string[];
  buttonLoading?: boolean;
  buttonWidth?: string | number;
  buttonColor?: string;
}


const props = withDefaults(defineProps<BPaypalButtonProps>(), {
  buttonType: () => ['primary', 'H80PX'],
  buttonLoading: false,
  buttonWidth: '100%',
  buttonColor: '#008CFF',
})
const emit = defineEmits(['click'])
const venmoBtnRef = ref(null)

// const randomKey = `pp-render-btn-${Math.random().toString(36).substring(8)}`

// watch([
//   () => props.createPaymentOptions,
//   () => props.currency,
//   () => props.clientToken,
//   () => props.paypalStyle,
// ], async () => {
//   if (typeof window === 'undefined' || !props.clientToken || !props.createPaymentOptions || !props.currency) {
//     return
//   }
//   await loadPaypalGaBtn({
//     ele: `.${randomKey}`,
//     createPaymentOptions: props.createPaymentOptions,
//     currency: props.currency,
//     clientToken: props.clientToken,
//     paypalStyle: props.paypalStyle,
//     onClick: props.onClick,
//     onApproved: props.onApproved,
//     onCancel: props.onCancel,
//     onError: props.onError,
//     onInit: props.onInit,
//   })
//   console.log('paypal button loaded')
//   console.time('paypal device info')
//   await getPaypalDeviceInfo({ clientToken: props.clientToken }).then((info) => {
//     console.log('paypal device info:', info)
//     emit('info-change', { paypalDeviceId: info })
//   })
//   console.timeEnd('paypal device info')
// }, { immediate: true })

// const styles = computed(() => ({
//   'width': props.propsStyle.width || '100%',
//   'height': props.propsStyle.height || '100%',
// }))

</script>

<style lang="less" scoped>
.bs-venmo-button{
  background-color: v-bind('buttonColor');
  border-radius: 8/75rem;
  border-color: transparent;
  &__icon{
    height: 26/75rem;
  }
}

</style>
