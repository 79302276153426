<template>
  <div class="all-privacy">
    <NewPrivacy
      v-if="isUseNewPaymentSecurity"
      :language="language"
      :logo-list="logoList" />
    <PrivacyModule
      v-else
      :language="language"
      :logo-list="logoList" />
  </div>
</template>

<script setup lang="ts">
import PrivacyModule from './privacy_module.vue'
import NewPrivacy from './new_privacy.vue'

import { computed } from 'vue'
import { usePaymentsStore } from '../../../../hooks/usePayments'

defineProps({
  language: {
    type: Object,
    default: () => {},
  },
})

const paymentState = usePaymentsStore()

const logoList = computed(() => {
  return paymentState?.paySafeIconsList || []
})

const isUseNewPaymentSecurity = computed(() => {
    console.log('paymentState >>>', paymentState)
    return true
  // return (
  //   checkout.value?.abtInfo?.PaymentSafetyExperiment?.param?.PaymentSecurity ===
  //   'Type_A'
  // )
})
</script>

<style lang="less" scoped>
.font-dpr(@font-size) {
    font-size: @font-size * 0.5;
}

.all-privacy {
  width: 100%;
  margin-top: 20/75rem;
  padding: 32/75rem 24/75rem 24/75rem 24/75rem;
  background-color: white;
  &:first-child {
    padding-top: 0;
  }
}

/deep/.support-info-title {
  color: #198055;
  .font-dpr(28px);
  line-height: 1.21428;
  min-height: 40/75rem;
  .icon-right {
    color: #767676;
  }
  .icon-width {
    width: 32/75rem;
    height: 32/75rem;
    display: inline-block;
    margin-right: 8/75rem;
  }
}
</style>
