<template>
  <SDrawer
    class="choose-bank-drawer"
    :visible.sync="visibleBanksDrawer"
    :direction="'btt'"
    :append-to-body="true"
    @close="close"
  >
    <template #top>
      {{ language.BS_KEY_PAY_1012 }}
    </template>
    <div class="choose-bank-items">
      <div
        v-for="(item, i) in bankList"
        :key="`${item.code}_${i}`"
        class="choose-bank-item"
        :class="{'disabled': item.is_gray == 1}"
        @click="handleSelectClick(item)"
      >
        <div class="choose-bank-item__logo">
          <img
            :src="transformImg({ img: item.logo })"
            alt=""
          />
        </div>
        <div
          class="choose-bank-item__name"
          :class="{'active': selectedCode === item.code }"
        >
          {{ item.name }}
        </div>
        <div class="choose-bank-item__select">
          <Icon
            v-show="selectedCode === item.code"
            name="sui_icon_selected_16px"
            size="16px"
            :is-rotate="cssRight"
          />
        </div>
      </div>
    </div>
  </SDrawer>
</template>

<script name="BankSelect" setup lang="ts">
import { Drawer as SDrawer } from '@shein/sui-mobile'
import { transformImg } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue2'
import { ref, computed } from 'vue'
import type { BankInfo, CollectInfoItem } from '../../../types/payments'

import useCapitecDialog from '../../../hooks/useCapitecDialog'
import { usePaymentsStore } from '../../../hooks/usePayments'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'


interface BankSelectProps {
  visible: boolean;
  language: Record<string, string>;
  bankList: BankInfo[];
  paymentCode: string;
  defaultCode?: string | number;
}

const props = withDefaults(defineProps<BankSelectProps>(), {
  visible: false,
  anguage: () => ({}),
  bankList: () => ([]),
  defaultCode: '',
})

const emit = defineEmits<{
  (close: 'close', bool: boolean): void
  (event: 'select', options: { bankInfo: BankInfo, collectInfo?: CollectInfoItem, paymentCode: string }): void
}>()

const { cssRight } = useAppConfigs()?.$envs || {}

const { openCapitecDialog } = useCapitecDialog()

const paymentStore = usePaymentsStore()

const selectedCode = ref(props.defaultCode)

const visibleBanksDrawer = computed({
  get: () => {
    console.log('visible', props.visible)
    return props.visible
  },
  set: (val) => {
    console.log('set visible', val)
    emit('close', val)
  },
})

const handleSelectClick = (item: BankInfo) => {
  if (item.is_gray == 1) return
  if (Number(item.collect_email) > 0 || Number(item.collect_phone_number) > 0) {
    openCapitecDialog({
      needCollectEmail: Number(item.collect_email) > 0,
      needCollectPhoneNumber: Number(item.collect_phone_number) > 0,
      paymentCode: props.paymentCode,
      bankInfo: item,
      countryCode: paymentStore.checkoutInfo?.addressInfo?.countryCode || '',
      countryId: paymentStore.checkoutInfo?.addressInfo?.countryId || '',
      handleConfirm: (collectInfo) => {
        emit('select', { bankInfo: item, collectInfo, paymentCode: props.paymentCode })
      },
    })
    return
  }
  selectedCode.value = item.code
  emit('select', { bankInfo: item, paymentCode: props.paymentCode })
}

const close = () => {
  visibleBanksDrawer.value = false

  paymentStore?.paymentEventBus?.paymentValidSendBi?.emit({
      result_reason: '1_4',
  })
}
</script>

<style lang="less">
.choose-bank-drawer {
  .choose-bank-items {
    width: 100%;
    max-height: 400/75rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .choose-bank-item {
    display: flex;
    align-items: center;
    padding: 24/75rem;
    line-height: 32/75rem;
    &.disabled {
      opacity: 0.4;
    }
    &__logo {
      width: 60/75rem;
      height: auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &__name {
      flex: 1 0 0;
      font-size: 14px;
      padding: 0 12/75rem;
      color: @sui_color_gray_dark1;
      &.active {
        font-weight: 700;
      }
    }
    &__select {
      min-width: 32/75rem;
      text-align: center;
    }
  }
}
</style>
