<template>
  <div class="mcheo-card mcheo-payment payment-options-container">
    <ul
      v-if="paymentMethodsOptions.length"
      class="j-payment-method mcheo-payment__methods">
      <PaymentItem
        v-for="(item, idx) in paymentMethodsOptions"
        :key="item.code"
        :idx="idx"
        :checkout="checkout"
        :item="item"
        :selected-pay="selectedPay"
        @click-payment="handleClickPayment"
      />
    </ul>
  </div>
</template>

<script>
import PaymentItem from './SimplePaymentItem.vue'

export default {
  name: 'PaymentOptions',
  components: {
    PaymentItem,
  },
  props: {
    sence: {
      type: String,
      default: 'simple-payment',
    },
    paymentMethodsOptions: {
      type: Array,
      default: function () {
        return []
      },
    },
    checkout: {
      type: Object,
      default: function () {
        return {}
      },
    },
    selectedPay: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['event'],

  computed: {
    IS_RW() {
      return this.locals?.IS_RW || gbCommonInfo.IS_RW
    },
  },
  methods: {
    // 选择支付方式
    handleClickPayment(item) {
      this.$emit('event', {
        type: 'clickPayment',
        data: item,
      })
    },
  },
}
</script>

<style lang="less" scoped>
.margin-l(@size) {
    margin-left: @size;
}
.font-dpr(@font-size) {
    font-size: @font-size * 0.5;
}
.border-dpr(@border,@num,@color) {
    @{border}: @num * 0.5 solid @color;
}
.padding-l(@size) {
    padding-left: @size;
}

.left(@size) {
    left: @size;
}

.font {
  font-family: Adieu-Regular, Adieu;
  font-weight: 400;
}
.mcheo-payment {
  margin-top: 0;

  &__more {
    padding: 0.4rem 0;
    color: @sui_color_gray_dark3;
    text-align: center;
  }
  &__methods,
  &__item {
    &.fold {
      position: absolute;
      top: 0;
      left: -1000%;
    }
  }
}
.payment-option-effiency {
  .mcheo-payment__more {
    padding: 20/75rem 0;
  }
  .payment-footer {
    .margin-l(64/75rem);
  }
}
.discount-tips-item {
  display: inline-block;
  color: @sui_color_promo_dark;
  padding: 4/75rem 8/75rem;
  margin-bottom: 6/75rem;
  .font-dpr(24px);
  line-height: 1;
  .border-dpr(border, 1px, @sui_color_prompt_unusual);
  background-color: #fff6f3;
  .icon {
    .font-dpr(24px) !important; /* stylelint-disable-line */
    line-height: 1;
    padding-left: 4/75rem;
    color: @sui_color_gray_light1;
  }
}

.mcheo-card {
  .mcheo-title {
    text-transform: capitalize;
    color: #222;
    background: #fff;
    padding-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */
    font-weight: 700;
    padding-top: 0.32rem !important; /* stylelint-disable-line declaration-no-important */
    .text-error {
      color: @sui_color_unusual;
      .margin-l(0.2rem);
      text-transform: initial;
    }
  }
  > ul {
    background: #fff;
    position: relative;
    .padding-l(0);

    .payment-loading {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      .left(0);
      z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
      .loading-cover {
        width: 100%;
        height: 100%;
        position: absolute;
        background: #fff;
        opacity: 0.5;
      }
      .loading-ball {
        position: absolute;
        top: 50%;
        margin-top: -0.25rem;
        .left(50%);
        .margin-l(-1.14rem);
      }
    }
  }

  .paytm-upi-tips {
    color: #666;
    .font-dpr(24px);
    padding-left: 1rem;
    padding-bottom: 0.12rem;
    .input-item {
      position: relative;
      padding: 0.1rem 0 0 0;
      input::-webkit-input-placeholder {
        color: #ccc;
      }
      input {
        width: 100%;
        padding: 0 10px;
        height: 36px;
        line-height: 36px;
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
      [class*='iconfont'] {
        font-size: 12px;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        display: none;
      }
      .icon-warn-out {
        color: #d53333;
      }
      .icon-checked {
        color: #5ebd66;
      }
    }
    .error-tips {
      color: #ff411c;
      display: none;
    }
    &.error-input-item {
      input {
        border-color: #ff411c;
      }
      .error-tips {
        display: block;
      }
      [class*='iconfont'] {
        display: block;
      }
    }
    &.succ-input-item {
      [class*='iconfont'] {
        display: block;
      }
    }
  }
}

.extra-tip {
  font-size: 12px;
  color: #666;

  a {
    text-decoration: none;
    display: inline-block;
    .icon-pro {
      font-size: 16px;
      vertical-align: middle;
    }
  }
}
.mshe-hide {
  display: none;
}
.all-discount-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.discount-percent-sy {
  margin: 0.1rem 0;
}

.ppgv-type-drawer {
  .type-des {
    padding: 0.32rem;
    color: #666;
    background-color: #f6f6f6;
    text-align: center;
    .font-dpr(24px);
  }
  .type-radio {
    border-bottom: 1px solid #f6f6f6;
  }
  .type-item {
    width: 100%;
    height: 1.17333rem;
    .margin-l(0.32rem);
    margin-left: 0.32rem;
    border-bottom: 1px solid #d9d9d9;
    &:last-child {
      border-bottom: none;
    }
  }
  .confirm-btn {
    padding: 0.2rem 0.32rem;
  }
}
</style>
