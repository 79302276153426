<template>
  <!-- 谷歌pay弹窗 -->
  <SDialog
    :visible.sync="visibleDialog"
    :immediately-render="true"
    :show-close="true"
    :close-on-click-modal="false"
    append-to-body
    class="googlepay-dialog-container"
    @close="handleClose"
    @close-from-icon="handleManualClose"
    @close-from-mask="handleManualClose"
  >
    <template #title>
      <div style="padding-bottom: 10px;">
        <img
          src="https://img.ltwebstatic.com/images3_ccc/2024/08/27/4a/172472845010ba60f4a3ca2175a1e36618e729e82a.png"
          style="height: 1.5rem; margin: 0 auto 0.6rem"
        />
        <div :id="customId"></div>
      </div>
    </template>
  </SDialog>
</template>

<script name="GooglePayDialog" setup lang="ts">
import { Dialog as SDialog } from '@shein/sui-mobile'
import { ref, watch } from 'vue'
import { useAnalysis } from '../../../common/analysisSource'
const { triggerNotice } = useAnalysis()

interface IGooglePayDialogProps {
  visible: boolean
  customId?: string
}

const visibleDialog = ref(false)

const props = withDefaults(defineProps<IGooglePayDialogProps>(), {
  visible: false,
  customId: 'bs-sdk-google-container',
})

const emits = defineEmits(['update:visible', 'close'])

watch(() => props.visible, (val) => {
  visibleDialog.value = val
}, { immediate: true })

const handleClose = () => {
  emits('update:visible', false)
}

const handleManualClose = () => {
  emits('update:visible', false)
  emits('close')
  triggerNotice({
    id: 'expose_google_pay_popup_disappear.comp_googlepay_dialog',
  })
}
</script>

