import { reactive, toRefs, isReactive,provide, inject, toRaw } from 'vue'
import { PAY_TOOLKIT_CONFIG_INJECT_KEY } from '../common/constants'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import { PRODUCT_TYPE, CHECKOUT_TYPE } from '@shein-aidc/bs-sdk-libs-pay'
import { debuggerLog } from '../utils'

export const defaultConfig: Trade_PayToolKit.PaymentConfig = {
  forceSignUp: false,
  productType: PRODUCT_TYPE.NORMAL,
  checkoutType: CHECKOUT_TYPE.NORMAL,
}

export const usePaymentConfig = () => {
  const initConfig = (props) => {
    const config = Object.assign({}, defaultConfig, toRaw(props.paymentConfig || {}))
    debuggerLog('usePaymentConfig=====', config, toRaw(props.paymentConfig || {}))
    const paymentConfigRef = isReactive(config) ? toRefs(config) : toRefs(reactive(config))

    provide(PAY_TOOLKIT_CONFIG_INJECT_KEY, paymentConfigRef)
  }

  const getPaymentConfig = () => {
    return inject(PAY_TOOLKIT_CONFIG_INJECT_KEY, defaultConfig)
  }

  return {
    initConfig,
    getPaymentConfig,
  }
}
