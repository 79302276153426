/**
 * 获取光标位置函数
 * @param dom	当前的输入框元素
 */

export function getCursortPosition(dom) {
  if (dom) {
    let pos = 0
    // IE Support
    if ((document as any).selection && (document as any).selection.createRange) {
      dom.focus()
      const sel = (document as any).selection.createRange()
      sel.moveStart('character', -dom.value.length)
      pos = sel.text.length

      // Firefox support
    } else if (dom.selectionStart || dom.selectionStart == '0') {
      pos = dom.selectionStart
    }
    return (pos)
  } else {
    return 0
  }
}

/**
 * 设置光标位置函数
 * @param dom	当前的输入框元素
 * @param pos	需要设置光标的下标值
 */
/**
 * 设置光标位置函数
 * @param dom	当前的输入框元素
 * @param pos	需要设置光标的下标值
 */
export function setCursortPosition(dom: HTMLInputElement, pos: number) {
  if (dom.setSelectionRange) {
    dom.focus()
    dom.setSelectionRange(pos, pos)
  } else if ((dom as any).createTextRange) {
    const range = (dom as any).createTextRange()
    range.collapse(true)
    range.moveEnd('character', pos)
    range.moveStart('character', pos)
    range.select()
  }
}

export function nextIdle() {
  return new Promise(resolve => {
    const rc = window.requestIdleCallback || window.setTimeout
    rc(() => resolve(''))
  })
}
