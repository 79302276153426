<template>
  <div
    v-if="visibleComp"
    class="discount-block"
  >
    <template v-for="(item, index) in formatPaymentsPreferentialTips">
      <div
        :key="index"
        v-expose="getExposeConfig(item)"
        class="discount-item"
      >
        <DiscountItem
          v-if="isNoBinCouponDiscount(item)"
          :payments-preferential-tips-item="item">
          <template #tip>
            <PaymentTips
              :icon-size="12"
              :show="!!(item && item.articleId && !(item.binDiscountTip && isTokenList))"
              tag-type="span"
              @click-doubt="handleClickDiscountDoubt(item)"
            />
          </template>
        </DiscountItem>

        <!-- 卡bin优惠券优惠展示和券信息相关，所以带上 ClientOnly -->
        <ClientOnly>
          <BinCouponsDiscount
            v-if="isShowBinCouponsDiscount(item)"
            :payments-preferential-tips-item="item"
            @handle-bs-payment-action="handleBsPaymentAction"
          />
        </ClientOnly>

        <span
          v-if="isShowAddTip(index, item)"
          class="add-tip">+</span>
        <PaymentTips
          :show="index + 1 === paymentsPreferentialTips.length && !!(item && item.articleId)"
          @click-doubt="handleClickDiscountDoubt(item)"
        />
      </div>
    </template>
  </div>
</template>

<script name="DiscountBlock" setup lang="ts">
/**
 * 折扣信息组件
 */
import { computed, inject } from 'vue'
import type { PaymentItemProps } from '../../../../types/props'
import DiscountItem from './DiscountItem.vue'
import BinCouponsDiscount from './BinCouponsDiscount.vue'
import PaymentTips from './PaymentTips.vue'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import { usePaymentsStore } from '../../../../hooks/usePayments'
import { debuggerLog } from '../../../../utils'
import ClientOnly from 'vue-client-only'
import { CO_BRAND_CARD_TIPS_TYPE, CO_BRAND_CARD_ARTICLE_URL } from '../../../../common/constants'
import { AS_PayToolkit } from '../../../../types'
const { vExpose, triggerNotice } = (inject('analysisInstance', null) || {}) as AS_PayToolkit.AnalysisInstance

const CARD_BIN_COUPONS_TYPE = 4 // 卡bin优惠券

interface DiscountPropsType {
  paymentItem: Trade_PayToolKit.PaymentInfoItem
  paymentsPreferentialTips: PaymentItemProps['paymentsPreferentialTips']
  nowPayBin: String
  couponListState?: Record<string, any>
  isTokenList?: boolean
  nowUseTokenInfo?: Record<string, any>
  scene?: 'payment_list' // 区分场景，其他场景用到的补充到类型里
}
type DiscountPropsItemType = PaymentItemProps['paymentsPreferentialTips'][number] & {
  binDiscountTip?: string
}

const emits = defineEmits(['click-tip', 'handle-bs-payment-action'])

const props = withDefaults(defineProps<DiscountPropsType>(), {
  paymentsPreferentialTips: () => [],
  paymentItem: () => ({} as Trade_PayToolKit.PaymentInfoItem),
  isTokenList: false,
  nowUseTokenInfo: () => ({}),
})

const paymentsStore = usePaymentsStore()

const formatPaymentsPreferentialTips = computed(() => {
  const binDiscountCardToken = (props.paymentItem?.binDiscountCardToken || {}) as Trade_PayToolKit.PaymentInfoItem['binDiscountCardToken']

  return (props.paymentsPreferentialTips?.map?.(item => {
    // 联名卡权益文章链接修正
    const coBrandTips = props.paymentsPreferentialTips?.find?.(coBrandTip => coBrandTip?.type === CO_BRAND_CARD_TIPS_TYPE)
    if (coBrandTips) {
      coBrandTips.articleId = CO_BRAND_CARD_ARTICLE_URL
    }

    if (
      binDiscountCardToken?.binDiscountTip
      && binDiscountCardToken?.is_selected === '1'
      && item?.type === '1'
      && binDiscountCardToken?.cardBin === props.nowPayBin // token 卡 bin 优惠
    ) {
      return {
        ...binDiscountCardToken,
        ...item,
        titles: [binDiscountCardToken?.binDiscountTip],
      }
    }
    return item
  }) || []).filter(item => {
    if (!props.isTokenList) return true
    return item?.type !== '1' || (item?.cardBin || '')?.length > 0 // 1、token优惠
  }) as DiscountPropsItemType[]
})

const visibleComp = computed(() => {
  return formatPaymentsPreferentialTips.value?.length > 0
})

const isSelectedToken = computed(() => {
  return Object.keys(props.nowUseTokenInfo || {}).length === 0 || props.nowUseTokenInfo?.card_bin === props.nowPayBin
})

const isNoBinCouponDiscount = (item: DiscountPropsItemType) => item?.uiType !== '2' // 2、卡bin优惠券
const isShowAddTip = (index: number, item: DiscountPropsItemType) => {
  return index + 1 < formatPaymentsPreferentialTips.value?.length && (isNoBinCouponDiscount(item) || isShowBinCouponsDiscount(item))
}

const handleClickDiscountDoubt = (item: DiscountPropsItemType) => {
  const { articleId } = item
  debuggerLog('articleId', articleId)
  emits('click-tip', articleId)

  // 联名卡权益点击埋点
  const trackCoBrandCardConfig = getClickConfig(item)
  if(trackCoBrandCardConfig?.id){
    triggerNotice?.(trackCoBrandCardConfig)
  }
}

const nowCouponList = computed(() => {
  const nowCouponListState = {
    ...(paymentsStore?.couponListState || {}),
    ...(props.couponListState || {}),
  }
  return [
    ...(nowCouponListState?.cmpAddCouponCoupons || []),
    ...(nowCouponListState?.cmpUsableCoupons || []),
    ...(nowCouponListState?.cmpBestCombinationCoupons || []),
  ]
})

// 当前卡bin 支持的卡 bin 优惠券
const nowUseCouponData = (discountInfo) => {
  debuggerLog('props.nowPayBin >>>>>', props.nowPayBin, nowCouponList.value, discountInfo)
  return nowCouponList.value?.find((item) => {
    if ((props.nowPayBin || '')?.length === 0) return false
    return item?.businessExtension?.order?.card?.cardCodes?.includes(props.nowPayBin) && isSelectedToken.value
  })
}

// 支付方式列表且是联名卡权益才需要上报埋点
const shouldTrackCoBrandCardEvent = (item: DiscountPropsItemType) => {
  if (props.scene !== 'payment_list') return false
  const isCoBrandCardTips = item?.type === CO_BRAND_CARD_TIPS_TYPE
  return isCoBrandCardTips
}

// 获取曝光联名卡权益埋点配置
const getExposeConfig = (item: DiscountPropsItemType) => {
  if (shouldTrackCoBrandCardEvent(item)) {
    return {
      id: 'expose_storicard_points:simple',
      data: {
        points_position: 'payment_list',
      },
    }
  }
  return undefined
}

// 获取点击联名卡权益埋点配置
const getClickConfig = (item: DiscountPropsItemType): {
  id: AS_PayToolkit.Events;
  data?: any;
} | undefined => {
  if (shouldTrackCoBrandCardEvent(item)) {
    return {
      id: 'click_storicard_points:simple',
      data: {
        points_position: 'payment_list',
      },
    }
  }
  return undefined
}

const isShowBinCouponsDiscount = (item) => {
  return item?.type == CARD_BIN_COUPONS_TYPE && nowUseCouponData(item)
}

const handleBsPaymentAction = (data) => {
  emits('handle-bs-payment-action', data)
}
</script>

<style lang="less" scoped>
.discount-block {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .discount-item {
    display: flex;
    margin-top: 4*2/75rem;
  }

  .add-tip {
    margin: 0 8/75rem;
  }
}
</style>
