<template>
  <div>
    <Icon
      name="sui_icon_more_down_16px_2"
      size="16px"
      class="ppgv-arrow"
      :is-rotate="cssRight"
      @click.stop="clickPaypalGaType"
    />
    <LazyMount>
      <SDrawer
        class="ppgv-type-drawer"
        :visible.sync="tipShow"
        :append-to-body="true"
      >
        <template #top>
          {{ language.BS_KEY_PAY_1036 }}
        </template>
        <div>
          <p class="type-des">
            {{ language.BS_KEY_PAY_1037 }}
          </p>
          <div
            class="type-radio"
          >
            <div @click="clickPaypalSelect(PPGA_CHECK_TYPE.CHECKED)">
              <SRadio
                :value="selectValue"
                class="type-item"
                :label="PPGA_CHECK_TYPE.CHECKED"
              >
                {{
                  accountInfo && accountInfo.signUpEmail
                    ? template(
                      accountInfo.signUpEmail,
                      language.BS_KEY_PAY_1038
                    )
                    : language.BS_KEY_PAY_1028
                }}
              </SRadio>
            </div>
            <div @click="clickPaypalSelect(PPGA_CHECK_TYPE.UNCHECKED)">
              <SRadio
                :value="selectValue"
                class="type-item type-item-last"
                :label="PPGA_CHECK_TYPE.UNCHECKED"
              >
                {{ language.BS_KEY_PAY_1039 }}
              </SRadio>
            </div>
          </div>

          <div class="confirm-btn">
            <SButton
              width="100%"
              :type="['primary']"
              @click="confirmPPGVType"
            >
              {{ language.BS_KEY_PAY_1040 }}
            </SButton>
          </div>
        </div>
      </SDrawer>
    </LazyMount>
  </div>
</template>

<script name="PPGATypeDrawer" setup lang="ts">
import { Icon } from '@shein-aidc/icon-vue2'
import { Drawer as SDrawer, Button as SButton, Radio as SRadio, LazyMount } from '@shein/sui-mobile'
import { ref, watch, inject } from 'vue'
import { template } from '@shein/common-function'
import { PPGA_CHECK_TYPE } from '../../../../types/payments'
import type { AS_PayToolkit } from '../../../../types'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

interface PPGATypeDrawerProps {
  language: Record<string, string>;
  defaultValue: PPGA_CHECK_TYPE;
  accountInfo: {
    signUpEmail: string;
  };
}

const { triggerNotice } = (inject('analysisInstance', null) || {}) as AS_PayToolkit.AnalysisInstance

const emit = defineEmits(['select'])

const props = withDefaults(defineProps<PPGATypeDrawerProps>(), {
  language: () => ({}),
  defaultValue: PPGA_CHECK_TYPE.UNCHECKED,
  ppgvAccountInfo: () => ({
    signUpEmail: '',
  }),
})

const { cssRight } = useAppConfigs()?.$envs || {}

const tipShow = ref(false)
const selectValue = ref<PPGA_CHECK_TYPE>(props.defaultValue)

const clickPaypalSelect = (type: PPGA_CHECK_TYPE) => {
  console.log('clickPaypalSelect=||==', type)
  // emit('select', type)
  selectValue.value = type
}

const clickPaypalGaType = () => {
  triggerNotice({
    id: 'expose_popup_ppacount.comp_ppga_type_drawer',
    data: {
      payment_method: 'PayPal-GApaypal',
    },
  })
  triggerNotice({
    id: 'click_paypalvaulting_choose:simple',
  })
  tipShow.value = true
}

const confirmPPGVType = () => {
  emit('select', selectValue.value)
  tipShow.value = false
}

watch(() => props.defaultValue, (val) => {
  selectValue.value = val
})
</script>

<style lang="less" scoped>
.font-dpr(@font-size) {
  font-size: @font-size * 0.5;
}

.ppgv-arrow {
  color: #999;
}

.ppgv-type-drawer {
  .type-des {
    padding: 0.32rem;
    color: #666;
    background-color: #f6f6f6;
    text-align: center;
    .font-dpr(24px);
  }
  .type-radio {
    border-bottom: 1px solid #f6f6f6;
  }
  .type-item {
    width: 100%;
    height: 1.17333rem;
    margin-left: .32rem;
    border-bottom: 1px solid #d9d9d9;
    &:last-child {
      border-bottom: none;
    }
  }
  .confirm-btn {
    padding: 0.2rem 0.32rem;
  }
}
</style>
