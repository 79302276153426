<template>
  <SDialog
    :visible.sync="visibleRememberCardTip"
    :show-close="true"
    append-to-body
  >
    <p>{{ language.BS_KEY_PAY_1180 }}</p>
    <template #footer>
      <SButton
        :type="['primary']"
        :width="'100%'"
        @click="visibleRememberCardTip = false"
      >
        {{ language.BS_KEY_PAY_1157 }}
      </SButton>
    </template>
  </SDialog>
</template>

<script setup>
import { Dialog as SDialog, Button as SButton } from '@shein/sui-mobile'
import { computed } from 'vue'

const emits = defineEmits(['update:visible'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  language: {
    type: Object,
    default: () => ({}),
  },
})

const visibleRememberCardTip = computed({
  get: () => props.visible,
  set: (val) => {
    emits('update:visible', val)
  },
})

</script>
