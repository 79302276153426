<template>
  <div v-if="isLoaded">
    <ChannelChallengeModule
      :visibleOptions="threeDsVisibleOptions"
      :paramOptions="threeDsChallengeModuleParams"
    />
    <GetDdcModule
      ref="ddcRef"
      v-bind="ddcOptions"
    />
  </div>
</template>

<script setup lang="ts" name="SpecialChannelModule">
import { defineAsyncComponent, ref, onMounted } from 'vue'
import { useSpecialChallenge } from '../../../hooks/useSpecialChallenge.ts'

const ChannelChallengeModule = defineAsyncComponent(() => import('./components/ChannelChallengePopup.vue'))
const GetDdcModule = defineAsyncComponent(() => import('./components/GetDdcModule.vue'))

const { threeDsVisibleOptions, threeDsChallengeModuleParams, ddcOptions, handleThreeDSecureModal, handleSetDdcOptions } = useSpecialChallenge()

// defineProps<{
//   threeDsOptions: {
//     threeDS1ChallengeShow: boolean
//     threeDS2ChallengeShow: boolean
//   };
//   ddcOptions: {
//     action: string
//     cardBin: string
//     jwt: string
//   }
// }>()

const isLoaded = ref(false)
const ddcRef = ref<any>(null)


defineExpose({
  ddcSubmit: (opts) => {
    handleSetDdcOptions(opts)
    ddcRef.value?.submit?.()
  },
  handleThreeDSecureModal,
})

onMounted(() => {
  isLoaded.value = true
})
</script>
