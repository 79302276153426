import useCodVerifyFree from '../../../hooks/useCodVerifyFree'
import createApp from './createApp'

const { store, initStore, fetchLanguage } = useCodVerifyFree()
class CodVerifyFreePopup {
  // 动态引入的组件，暂时不处理类型
  instance: any

  constructor() {
    this.instance = null
  }

  async initData(initData: Partial<typeof store.value>) {
    const { order = {}, needCountdown = false, onValidateFail, onValidateSuccess, onClose, onStartValidate, onCreate } = initData || {}
    // const languageInfo = await fetchLanguage()
    // store.value.language = languageInfo
    store.value.needCountdown = needCountdown
    store.value.order = order
    store.value.onValidateFail = onValidateFail || (() => {})
    store.value.onValidateSuccess = onValidateSuccess || (() => {})
    store.value.onClose = onClose || (() => {})
    store.value.onStartValidate = onStartValidate || (() => {})
    store.value.onCreate = onCreate || (() => {})
  }

  async createInstance() {
    if (this.instance) return
    this.instance = await createApp()
  }

  async initPopup<T extends Partial<typeof store.value>>(config: T) {
    initStore()
    await this.initData(config)
    await this.createInstance()
  }

  async createPopup<T extends Partial<typeof store.value> >(config: T) {
    await this.initPopup(config)
    if (this.instance) {
      this.instance.show()
      return
    }
  }
}

export default new CodVerifyFreePopup()
