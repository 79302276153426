import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { useEditablePaymentInfo } from './useEditablePaymentInfo'
import type { PaymentItemProps } from '../types/props'
import { debuggerLog } from '../utils'

interface UseGetInstallmentsParams {
    paymentCode?: string,
    cardBin?: string,
    cardType?: string,
    billno?: string,
    orderCurrency?: string,
    cb?: (info: any) => void,
}

interface ChangePayStoreInstallmentsParams {
    pay: PaymentItemProps,
    installments: Record<string, any>,
}


// 获取分期信息
export const useGetInstallments = ({ paymentStore }) => {
    const appConfigs = useAppConfigs()
    const { handleEditableInfoChange } = useEditablePaymentInfo()

    const getInstallments = async (useGetInstallmentsParams: UseGetInstallmentsParams) => {
        const { checkoutInfo = {} } = paymentStore
        try {
            const info = await appConfigs.$schttp({
                url: '/pay/paycenter/installments',
                method: 'POST',
                params: {
                    orderAmount: checkoutInfo?.totalPriceInfo?.amount || '',
                    paymentCode: useGetInstallmentsParams?.paymentCode || '',
                    cardBin: useGetInstallmentsParams?.cardBin || '',
                    cardType: useGetInstallmentsParams?.cardType || '',
                    orderCurrency: useGetInstallmentsParams?.orderCurrency || checkoutInfo?.currencyCode || checkoutInfo?.orderCurrencyInfo?.code || appConfigs?.$envs?.currency || '',
                    countryCode: checkoutInfo?.addressInfo?.countryCode || '',
                    billno: useGetInstallmentsParams?.billno || '',
                 },
            })

            useGetInstallmentsParams?.cb?.(info)
        } catch(err) {
            debuggerLog('err >>>>>>>', err)
        }
    }

    const changePayStoreInstallments = (changePayStoreInstallmentsParams: ChangePayStoreInstallmentsParams) => {
        handleEditableInfoChange({
            changeInfo: {
                [changePayStoreInstallmentsParams?.pay?.code]: {
                    ...(paymentStore?.editablePaymentInfo?.[changePayStoreInstallmentsParams?.pay?.code] || {}),
                    ...(changePayStoreInstallmentsParams?.installments || {}),
                },
            },
            needEmitChangeEvent: true,
        })
    }

    return {
        getInstallments,
        changePayStoreInstallments,
    }
}
