export const PAY_TOOLKIT_CONSTANTS_INJECT_KEY = Symbol('pay-toolkit-constants-inject-key')
export const PAY_TOOLKIT_BS_INJECT_KEY = Symbol('pay-toolkit-bs-inject-key')
export const PAY_TOOLKIT_PAYMENTS_INJECT_KEY = Symbol('pay-toolkit-payments-inject-key')
export const PAY_TOOLKIT_EVENT_BUS_INJECT_KEY = Symbol('pay-toolkit-event-bus-inject-key')
export const PAY_TOOLKIT_PRE_PAY_INJECTION_KEY = Symbol('bs-pre--pay-injection-key')
export const PAY_TOOLKIT_PRE_PAY_MODAL_INJECTION_KEY = Symbol('bs-pre-pay-modal-injection-key')
export const PAY_TOOLKIT_PRE_PAY_EVENT_BUS_KEY = Symbol('prePayEventBusKey')
export const PAY_TOOLKIT_CONFIG_INJECT_KEY = Symbol('pay-toolkit-config-inject-key')

// 卡种枚举
export const CARD_TYPE_ENUM = {
  AMEX: 'amex',
}

// export const VIRTUAL_TOKEN_ID = '00_9969900'

export const events = new Map()

export const FORMAT_TAX_NUMBER = ['ebanx-clcardinstallment']

export const ABT_POS_KEYS = [
  'Platformitemsvaultingoption',
  'cardinstallmentPaymentFront',
  'BinDiscountdiscount',
  'InstalUIUpgrade',
  'InstalDefaultChosen',
  'checkCardinstallmentInfo',
  'ApplepayDropoffOptimization',
] as const

export type AbtPosKeysTypes = (typeof ABT_POS_KEYS)[number]

// 联名卡logo
export const CO_BRAND_CARD_LOGO =
  'https://img.ltwebstatic.com/images3_ccc/2024/06/20/8d/171885255347f540b64c2435864c9a352119a4fd2d.png'

// 联名卡logo缩略图
export const CO_BRAND_CARD_LOGO_THUMBNAIL =
  'https://img.ltwebstatic.com/images3_ccc/2024/11/01/e7/17304432857a84dc80b71cae73d2f9faff24f14cdd.png'

// 联名卡权益文章链接
export const CO_BRAND_CARD_ARTICLE_URL = 'https://m.shein.com/mx/article-shein-card-benefit-rules.html'

// 支付方式列表，联名卡权益文案的类型
export const CO_BRAND_CARD_TIPS_TYPE = '3'

// 支付方式列表，联名卡权益文案的ui类型
export const CO_BRAND_CARD_FIRST_ORDER_UI_TYPE = '3'

// 卡bin属于联名卡的类型枚举，1：White，2：Indigo
export const CO_BRAND_LEVEL_LIST = [1, 2]

export const EXPOSE_PREFIX = {
  PAYMENT_ITEM: 'expose_payment_method_item',
}
