import { nextTick } from 'vue'
import { type PaymentsStore } from './usePayments'

export const useScrollToPayment = (paymentStore: PaymentsStore) => {

  const scrollToPaymentByCode = async (paymentCode: string) => {
    if (typeof window === 'undefined') {
      return
    }
    // TODO: 楼层置底逻辑，需要拉起支付方式弹窗

    // 取消支付方式折叠
    paymentStore.mergeState('paymentItemsConfig', {
      foldedPosition: 1,
    })

    await nextTick()

    const bnplPayment = paymentStore.payments.find((item) => {
      const payments = Array.isArray(item.payments) ? item.payments : []
      return payments.length > 0
    })

    const bnplCode = bnplPayment?.payments?.map?.((item) => item.code) || []

    const isBnpl = bnplCode.includes(paymentCode)

    if (isBnpl) {
      document.querySelector('.bs-payment-item[data-p-c="bnpl"]')?.scrollIntoView?.({
        block: 'center',
      })
      // 拉起bnpl弹窗
      await nextTick()
      paymentStore.mergeState('bnplInfo', {
        payments: bnplPayment.payments,
        visibleBnpl: true,
      })
    }
    await nextTick()
    document.querySelector(`.bs-payment-item[data-p-c="${paymentCode}"]`)?.scrollIntoView?.({
      behavior: 'smooth',
      block: 'center',
    })
  }

  return {
    scrollToPaymentByCode,
  }
}

