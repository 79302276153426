import * as standard from './sources/standard/index'
import { organizeDataSource, createFsDataRunnerBySource } from '@shein-aidc/utils-data-source-toolkit'
import type { DS_PayToolkit } from '../../types'

export function getSource({ dataSource = {}, appConfigs }: DS_PayToolkit.SourceParams): DS_PayToolkit.APIS {
  return organizeDataSource<DS_PayToolkit.APIS, DS_PayToolkit.DataSource>(
    {
      standard,
    },
    dataSource,
    appConfigs,
  )
}

export function getFsDataRunner(sourceParams: DS_PayToolkit.FS_SourceParams) {
  const { getLanguageInfo, getAbtInfoApi } = getSource(sourceParams)
  return createFsDataRunnerBySource<DS_PayToolkit.FS_APIS, DS_PayToolkit.FS_DATA>({
    getLanguageInfo,
    getAbtInfoApi,
  })
}
