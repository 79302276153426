import { computed, ref, watch } from 'vue'
// import { usePaymentsStore } from './usePayments'
import type { PaymentItemsProps } from '../types/props'

export const useViewMore = (props: PaymentItemsProps) => {
  const isClickViewMore = ref(false)

  const foldedPositionIndex = computed(() => {
    const originPos = Number(props.paymentItemsConfig?.foldedPosition) || 1
    return (originPos - 1) || 0
  })

  const foldPositionPayments = computed(() => {
    if (!foldedPositionIndex.value || isClickViewMore.value) return []
    return props.payments?.filter?.((_, index) => index > foldedPositionIndex.value) || []
  })

  const viewMoreIconList = computed(() => {
    return foldPositionPayments.value?.reduce<string[]>((acc, cur) => {
      if (cur?.card_logo_list?.length) {
        // 卡支付方式且卡组logo不为空, 取卡组logo前三个icon
        const cardIcons = cur.card_logo_list.slice(0, 3)
        return [...acc, ...cardIcons]
      } else {
        return [...acc, cur.logo_url]
      }
    }, [])
  })

  const stopWatch = watch(() => foldedPositionIndex.value, (val) => {
    if (val === 0) {
      isClickViewMore.value = true
      stopWatch()
    }
  })

  const handleClickViewMore = () => {
    isClickViewMore.value = true
  }

  return {
    isClickViewMore,
    foldPositionPayments,
    viewMoreIconList,
    handleClickViewMore,
    foldedPositionIndex,
  }
}
