<template>
  <div class="discount-tips-item">
    <div class="payment-discount-tip">
      <span v-html="tips"></span>
      <slot name="tip"></slot>
    </div>
  </div>
</template>

<script name="DiscountItem" setup lang="ts">
import { processCoBrandCardBenefits } from '../../../../hooks/useCoBrandCard'
import { CO_BRAND_CARD_TIPS_TYPE } from '../../../../common/constants'
import { computed } from 'vue'

/**
 * 折扣信息组件
 */

interface paymentsPreferentialTipsType {
  paymentsPreferentialTipsItem: {
    titles: string[]
    imageUrl?: string
    type?: string
    articleId?: string
    uiType?: string
    hasUsedCardBinCoupon?: string | null
    cardBinCouponCode?: string | null
  }
}

const props = withDefaults(defineProps<paymentsPreferentialTipsType>(), {
  paymentsPreferentialTipsItem: () => ({
    titles: [],
    imageUrl: '',
    type: '',
    articleId: '',
    uiType: '',
    hasUsedCardBinCoupon: null,
    cardBinCouponCode: null,
  }),
})

// 权益文案处理，联名卡需要特殊处理，首单展示 logo
const tips = computed(() => {
  const { type, titles } = props.paymentsPreferentialTipsItem || {}
  const firstTitle = titles?.[0] || ''
  switch (type) {
    case CO_BRAND_CARD_TIPS_TYPE:
      {
        return processCoBrandCardBenefits(props.paymentsPreferentialTipsItem)
      }
    default:
      return firstTitle
  }
})
</script>

<style lang="less" scoped>
.font-dpr(@font-size) {
    font-size: @font-size * 0.5;
}

.border-dpr(@border,@num,@color) {
    @{border}: @num * 0.5 solid @color;
}

.discount-tips-item {
  display: inline-block;
  color: #c44a01;
  padding: 4/75rem 8/75rem;
  margin-bottom: 6/75rem;
  .font-dpr(20px);
  line-height: 1;
  .border-dpr(border, 1px, #ffe1db);
  background-color: #fff6f3;
  .icon {
    .font-dpr(20px) !important; /* stylelint-disable-line */
    line-height: 1;
    padding-left: 4/75rem;
    color: #959595;
  }
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: unset;

  .payment-discount-tip {
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
</style>
