import { reactive, nextTick } from 'vue'
import { debuggerLog } from '../utils'

export interface HandleThreeDSecureModalParams {
  visible: boolean;
  level: '1.0' | '2.0';
  paramList?: any;
  actionUrl?: string;
  action?: (info?: { threeDs2Id: string }) => void;
}

export const useSpecialChallenge = () => {
  const threeDsOptions = reactive({
    threeDS1Options: {
      paramList: {},
      actionUrl: '',
    },
  })

  const threeDsVisibleOptions = reactive({
    threeDS1ChallengeShow: false,
    threeDS2ChallengeShow: false,
  })

  const threeDsChallengeModuleParams = reactive({
    threeDS1DomId: 'specail-challenge-3ds1',
    threeDS2DomId: 'specail-challenge-3ds2',
  })

  const ddcOptions = reactive({
    action: '',
    cardBin: '',
    jwt: '',
  })

  function makeForm ({params, action, method = 'post'}: {params: any; action: string; method?: string;}) {
    const formName = 'bs_pay_challenge_form'
    let form = document.querySelector(`form[name="${formName}"]`)
    if (form && form.parentNode) {
      form.parentNode?.removeChild?.(form)
    }

    method = method || 'post'
    form = document.createElement('form')
    form.setAttribute('name', formName)
    form.setAttribute('action', action)
    form.setAttribute('method', method)

    const fragment = document.createDocumentFragment()

    for (const key in params) {
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', key)
      input.setAttribute('value', params[key])
      fragment.appendChild(input)
    }
    const submitInput = document.createElement('input')
    submitInput.setAttribute('type', 'submit')
    submitInput.setAttribute('value', 'Continue')
    submitInput.style.display = 'none'
    fragment.appendChild(submitInput)

    form.appendChild(fragment)

    document.body.appendChild(form)
    document[formName]?.submit?.()
  }

  const createChannelFrameWrap = ({params, action, method = 'post', target}: {params: any; action: string; method?: string; target: HTMLElement}) => {
    const frameName = 'bs_pay_frame_challenge'
    const formName = 'bs_pay_challenge_form'
    let frame = document.querySelector(`iframe[name="${frameName}"]`)
    if (frame && frame.parentNode) {
      frame.parentNode?.removeChild?.(frame)
    }

    const fragment = document.createDocumentFragment()

    frame = document.createElement('iframe')
    frame.setAttribute('name', frameName)
    frame.setAttribute('width', '400')
    frame.setAttribute('height', '250')
    fragment.appendChild(frame)

    const formFragment = document.createDocumentFragment()

    method = method || 'post'
    const form = document.createElement('form')
    form.setAttribute('name', formName)
    form.setAttribute('target', frameName)
    form.setAttribute('action', action)
    form.setAttribute('method', method)

    for (const key in params) {
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', key)
      input.setAttribute('value', params[key])
      formFragment.appendChild(input)
    }
    const submitInput = document.createElement('input')
    submitInput.setAttribute('type', 'submit')
    submitInput.setAttribute('value', 'Continue')
    submitInput.style.display = 'none'
    formFragment.appendChild(submitInput)

    form.appendChild(formFragment)
    fragment.appendChild(form)


    if (target) {
      target.appendChild(fragment)
      nextTick(() => {
        target?.[formName]?.submit?.()
      })
    } else {
      document.body.appendChild(fragment)
      nextTick(() => {
        document[formName]?.submit?.()
      })
    }
  }

  // function _handleMessageChange (event) {
  //   const types = ['challenge', 'normal', 'ocpResult']
  //   debuggerLog('event---message----', event.data)
  //   if (event.origin == location.origin && event.data) {
  //     try {
  //       const data =
  //           typeof event.data === 'string'
  //             ? JSON.parse(event.data)
  //             : event.data || {}
  //       if (
  //         data !== undefined &&
  //           data.app === 'shein' &&
  //           types.includes(data.type)
  //       ) {
  //         const result = typeof data.result === 'string' ? JSON.parse(data.result) : data.result || {}
  //         debuggerLog('unHandleResult--result-----', result)
  //         handleThreeDSecureModal({ visible: false })
  //         // const { status } = unHandleResult(result) || {}
  //         emit('sdk-bridge-action', { action: 'onChallengePayComplete', payload: { result } })
  //         debuggerLog('unHandleResult--result--status-----', status)
  //         // if (status) {
  //         //   tokenPayConfig.value.onPayComplete?.({ status, result })
  //         //   window.removeEventListener('message', _handleMessageChange, false)
  //         // }
  //       }
  //     } catch (e) {
  //       debuggerLog('postMessage error: ' + e)
  //     }
  //   }
  // }

  // 3ds2.0 挑战
  const handleThreeDSecure2Modal = ({ visible, action }: Omit<HandleThreeDSecureModalParams, 'level'>) => {
    action?.({ threeDs2Id: threeDsChallengeModuleParams.threeDS2DomId })
    debuggerLog('handleThreeDSecure2Modal===', visible, action, threeDsChallengeModuleParams.threeDS2DomId)
    if (visible) {
      threeDsVisibleOptions.threeDS2ChallengeShow = true
    } else {
      threeDsVisibleOptions.threeDS2ChallengeShow = false
    }
  }

  const handleThreeDSecure1Modal = ({ visible, paramList, actionUrl }: Omit<HandleThreeDSecureModalParams, 'level'>) => {
    if (visible) {
      threeDsOptions.threeDS1Options.paramList = paramList
      threeDsOptions.threeDS1Options.actionUrl = actionUrl || ''
      threeDsVisibleOptions.threeDS1ChallengeShow = true
      nextTick(() => {
        createChannelFrameWrap({ params: paramList, action: actionUrl || '', method: 'post', target: document.getElementById(threeDsChallengeModuleParams.threeDS1DomId) as HTMLElement })
        // makeForm({ params: paramList, action: actionUrl || '' })
      })
    } else {
      threeDsVisibleOptions.threeDS1ChallengeShow = false
      threeDsOptions.threeDS1Options.paramList = {}
      threeDsOptions.threeDS1Options.actionUrl = ''
    }
  }

  const handleThreeDSecureModal = ({ visible, level, paramList, actionUrl, action }: HandleThreeDSecureModalParams) => {
    debuggerLog('handleThreeDSecureModal===', visible, level, paramList, actionUrl)
    if (level === '1.0') {
      handleThreeDSecure1Modal({ visible, paramList, actionUrl })
    } else if (level === '2.0') {
      handleThreeDSecure2Modal({ visible, action })
    }
  }

  const handleSetDdcOptions = ({ action, cardBin, jwt }) => {
    ddcOptions.action = action
    ddcOptions.cardBin = cardBin
    ddcOptions.jwt = jwt
  }

  return {
    threeDsOptions,
    ddcOptions,
    threeDsVisibleOptions,
    threeDsChallengeModuleParams,
    handleThreeDSecureModal,
    handleSetDdcOptions,
  }
}
