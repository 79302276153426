<template>
  <div
    v-if="visible"
    class="card-logo-list"
  >
    <div class="top-content">
      <slot name="change-to"></slot>
      <img
        v-for="(src, index) in cardLogoList"
        :key="`card-logo-${index}`"
        class="card-logo-img"
        :src="transformImg({ img: src })"
      />
      <slot name="discount"></slot>

      <div
        v-if="showRightSlot"
        class="right-slot">
        <slot name="right-slot"></slot>
      </div>
    </div>
    <div
      v-if="showBottomSlot"
      class="bottom-slot">
      <slot name="bottom-slot"></slot>
    </div>
  </div>
</template>

<script name="BPayPaymentLogoList" setup lang="ts">
import { transformImg } from '@shein/common-function'
import { computed, type PropType, useSlots } from 'vue'

const slots = useSlots()

const props = defineProps({
  cardLogoList: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
})

const visible = computed(() => props.cardLogoList?.length > 0)

const showBottomSlot = computed(() => !!slots?.['bottom-slot']?.())
const showRightSlot = computed(() => !!slots?.['right-slot']?.())

</script>

<style lang="less" scoped>
.card-logo-list {
  margin-top: 12/75rem;
  display: flex;
  flex-direction: column;

  .top-content {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;

    .right-slot {
      position: absolute;
      right: 0;
    }
  }

  .bottom-slot {
    .discount-tips-item {
      border: unset;
      background-color: unset;
      font-size: 12px;
      padding-left: 0;
    }
  }
}
.card-logo-img {
  width: auto;
  height: 32/75rem;
  margin-right: 8/75rem;
  margin-bottom: 8/75rem;
}
</style>
