<template>
  <div class="payments-main-container">
    <PaymentItems
      :payments="payments"
      :language="language"
      :selected-payment="paymentState && paymentState.selectedPayment"
      :sign-up-info="editablePaymentInfo"
      :paymentItemsConfig="paymentItemsConfig"
      @editable-info-change="handleChangeEvt"
      @select="handleSelectEvt"
    />
  </div>
</template>

<script name="MainPayments" setup lang="ts">
import { usePaymentsStore } from '../../../hooks/usePayments'
import PaymentItems from '../payment-items/PaymentItems.vue'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import type { PaymentItemSelectType, PaymentItemsConfig } from '../../../types'
import { useEditablePaymentInfo } from '../../../hooks/useEditablePaymentInfo'
import { debuggerLog } from '../../../utils'


interface MainPaymentsProps {
  language: Record<string, string>;
  payments: Trade_PayToolKit.PaymentInfoItem[];
  paymentItemsConfig: PaymentItemsConfig;
}

withDefaults(defineProps<MainPaymentsProps>(), {
  payments: () => ([]),
  language: () => ({}),
  paymentItemsConfig: () => ({}),
})

// const emit = defineEmits<{
//   select: [info: AssembledToOutsidePaymentInfo], // named tuple syntax
//   'payment-info-change': [info: AssembledToOutsidePaymentInfo]
// }>()

const emit = defineEmits<{
  (event: 'select', info: { type: PaymentItemSelectType; paymentItem: Trade_PayToolKit.PaymentInfoItem, triggerSelectHook: boolean }): void
}>()

const { handleEditableInfoChange, editablePaymentInfo } = useEditablePaymentInfo()

const paymentState = usePaymentsStore()

const changeCardTokenInfo = (pay) => {
  if (paymentState?.editablePaymentInfo?.[pay?.code]?.card_token_info && (pay?.card_token_list || []).length === 0) {
    handleEditableInfoChange({
        changeInfo: {
            [pay?.code]: {
                ...(paymentState?.editablePaymentInfo?.[pay?.code] || {}),
                card_token_info: {},
            },
        },
    })
  }
}

const handleSelectEvt = ({ type, paymentItem }: { type: PaymentItemSelectType; paymentItem: Trade_PayToolKit.PaymentInfoItem }) => {
  changeCardTokenInfo(paymentItem)
  emit('select', { type, paymentItem, triggerSelectHook: true })
}

const handleChangeEvt = (info: { [key: Trade_PayToolKit.PAYMENT_CODE_KEY]: Trade_PayToolKit.EditablePaymentItem }) => {
  debuggerLog('handleChangeEvt', info)
  handleEditableInfoChange({
    changeInfo: info,
    needEmitChangeEvent: true,
  })
}

// onMounted(() => {
//   paymentState.paymentEventBus.selectedFinish.on(() => {
//     // emit('select', handleAssembledToOutsidePaymentInfo())
//     emit('payment-info-change', handleAssembledToOutsidePaymentInfo())
//   })
// })

defineExpose({
  handleChangeEvt,
})
</script>

<style scoped lang="less">
.payments-main-container {
  padding: 0 var(--bs-payments-container-main-padding);
}
</style>
