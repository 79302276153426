import type { C_PayToolkit, DS_PayToolkit, IGetPaymentRelatedInfoRes, IGetCardBinDiscountArticleRes, IGetTelCountryListRes } from '../../../../types'
import { getLangs } from '@shein-aidc/bs-sdk-libs-manager'
import langsMap, { type LangKeys } from '../../../languages'
import { ABT_POS_KEYS, type AbtPosKeysTypes } from '../../../constants'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'

// 获取多语言key
export const getLanguageInfo: DS_PayToolkit.APIS_THIS['getLanguageInfo'] = async function (params) {
  // const project = this.$envs.project
  const { langs } = params || {}
  const result = await getLangs<LangKeys>(langs as unknown as Record<LangKeys, string> || langsMap[this.$envs.project], this.$schttp)
  return result
}

export const getAbtInfoApi: DS_PayToolkit.APIS_THIS['getAbtInfoApi'] = async function () {
  const stringifyPosKeys = ABT_POS_KEYS.join(',')
  const result = await this.$schttp<Trade_PayToolKit.GetAbtInfoRes<AbtPosKeysTypes>>({
    url: '/abt/merge/get_abt_by_poskey',
    method: 'GET',
    params: stringifyPosKeys ? { posKeys: stringifyPosKeys } : {},
    headers: {
      AppCurrency: this.$envs.currency,
      AppLanguage: this.$envs.appLanguage,
    },
  })
  return result.data
}

export const deletePaymentSignUpApi: DS_PayToolkit.APIS_THIS['deletePaymentSignUpApi'] = async function({ data, currency, appLanguage }) {
  const result = await this.$schttp<Array<number>>({
    url: '/pay/del_worldpay_token',
    method: 'POST',
    params: data,
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage,
    },
  })
  return result.data || result
}

export const getPaymentRelatedInfoApi: DS_PayToolkit.APIS_THIS['getPaymentRelatedInfoApi'] = async function({ data, currency, appLanguage }) {
  const result = await this.$schttp<IGetPaymentRelatedInfoRes>({
    url: '/pay/payment/get_user_payment_related_info',
    method: 'POST',
    data,
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage,
    },
  })
  return result.data || result
}

export const getCardBinDiscountArticleApi: DS_PayToolkit.APIS_THIS['getCardBinDiscountArticleApi'] = async function({ articleId }) {
  const result = await this.$schttp<IGetCardBinDiscountArticleRes>({
    baseURL: this.$envs?.langPath,
    url: `/product/article/${articleId}`,
    params: {
      isJson: true,
    },
  })
  return result.data || result
}

export const getTelCountryListApi: DS_PayToolkit.APIS_THIS['getTelCountryListApi'] = async function ({ currency, appLanguage }) {
  const result = await this.$schttp<IGetTelCountryListRes>({
    url: '/user/address/get_country_site_list',
    method: 'GET',
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage,
    },
  })
  return result.data || result
}

// /order/get_order_detail
export const getOrderDetailApi: DS_PayToolkit.APIS_THIS['getOrderDetailApi'] = async function (params) {
  const result = await this.$schttp<C_PayToolkit.BaseApiRes<Record<string, any>>>({
    url: '/order/get_order_detail',
    method: 'GET',
    params,
    headers: {
      AppCurrency: this.$envs.currency,
      AppLanguage: this.$envs.appLanguage,
    },
  })
  return result.data || result
}

export const checkAliasApi: DS_PayToolkit.APIS_THIS['checkAliasApi'] = async function (data) {
  const result = await this.$schttp<C_PayToolkit.BaseApiRes<Record<string, any>>>({
    baseURL: this.$envs?.langPath,
    url: '/api/auth/phoneValidate/get',
    method: 'POST',
    data,
    headers: {
      AppCurrency: this.$envs.currency,
      AppLanguage: this.$envs.appLanguage,
    },
  })
  return result.data || result
}
