<template>
  <s-dialog
    :visible.sync="visibleTips"
    :show-close="true"
    append-to-body
  >
    <p style="text-align: center;">
      {{ language.BS_KEY_PAY_1160 }}
    </p>
    <template slot="footer">
      <s-button
        :type="['primary']"
        :width="'100%'"
        @click="visibleTips = false"
      >
        {{ language.BS_KEY_PAY_1157 }}
      </s-button>
    </template>
  </s-dialog>
</template>

<script setup>
import { Dialog as SDialog, Button as SButton } from '@shein/sui-mobile'
import { computed } from 'vue'

const emits = defineEmits(['update:visible'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  language: {
    type: Object,
    default: () => ({})
  }
})

const visibleTips = computed({
  get: () => props.visible,
  set: (val) => {
    emits('update:visible', val)
  }
})

</script>
