import { defineComponent, PropType, VNode } from 'vue'

const RenderByVNode = defineComponent({
  functional: true,
  props: {
    render: {
      type: Function as PropType<() => VNode>,
      required: true,
    },
  },
  render(_, context) {
    return context.props.render()
  },
})

export default RenderByVNode
