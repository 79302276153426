import { Trade_PayToolKit } from '@shein-aidc/types-trade'
import { cloneDeep } from '@shein/common-function'
interface IGoodsInfo {
  businessModel?: string
  companyId?: string
  business_model?: string
  company_id?: string
}

/**
 * 获取光标位置函数
 * @param dom	当前的输入框元素
 */
export function getCursortPosition(dom: HTMLInputElement) {
  if (dom) {
    let pos = 0
    // IE Support
    if ((document as any).selection && (document as any).selection.createRange) {
      dom.focus()
      const sel = (document as any).selection.createRange()
      sel.moveStart('character', -dom.value.length)
      pos = sel.text.length

      // Firefox support
    } else if (dom.selectionStart || dom.selectionStart == 0) {
      pos = dom.selectionStart
    }
    return (pos)
  } else {
    return 0
  }
}

/**
 * 设置光标位置函数
 * @param dom	当前的输入框元素
 * @param pos	需要设置光标的下标值
 */
export function setCursortPosition(dom: HTMLInputElement, pos: number) {
  if (dom.setSelectionRange) {
    dom.focus()
    dom.setSelectionRange(pos, pos)
  } else if ((dom as any).createTextRange) {
    const range = (dom as any).createTextRange()
    range.collapse(true)
    range.moveEnd('character', pos)
    range.moveStart('character', pos)
    range.select()
  }
}

export function debuggerLog(...args) {
  if (typeof window === 'undefined') return
  try {
    const enableDebugLog = sessionStorage?.getItem?.('__bs__enable_debug_log')
    if (!enableDebugLog) return
    console.info(
      '%cBS Pay Toolkit Debug Info:',
      'background:#35495e ; padding: 3px 3px; border-radius: 3px;  color: #fff',
      ...args,
      '\n',
    )
  } catch (e) {
    console.log(...args)
  }
}

export function getOrderAttributesList ({
  normalGoodsList = [],
  usedPrimeProductInfo = {},
  usedXtraProductInfo = {},
}) {
  let orderAttributesList: any[] = []

  const formatAttribute = (goodsInfo: IGoodsInfo = {}) => {
    const { businessModel, business_model, companyId, company_id } = goodsInfo || {}
    return {
      businessModel: businessModel || business_model,
      companyId: companyId || company_id,
    }
  }

  const uniqueBusinessInfo = (list: IGoodsInfo[] = []) => {
    // 需去重
    const hashMap = {}
    return list.reduce((pre, cur) => {
      const hash = `${cur.businessModel}:${cur.companyId || ''}`
      if (!hashMap[hash]) {
        hashMap[hash] = true
        return [...pre, cur]
      }
      return pre
    }, [] as any[])
  }

  orderAttributesList = [
    ...(normalGoodsList?.map?.(item => formatAttribute(item)).filter(v => v.businessModel != null) || []),
  ]
  const primeAttribute = formatAttribute(usedPrimeProductInfo)
  const xtraAttribute = formatAttribute(usedXtraProductInfo)
  if (primeAttribute.businessModel != null) orderAttributesList.push(primeAttribute)
  if (xtraAttribute.businessModel != null) orderAttributesList.push(xtraAttribute)

  return uniqueBusinessInfo(orderAttributesList)
}

export function nextIdle() {
  return new Promise(resolve => {
    const rc = window.requestIdleCallback || window.setTimeout
    rc(() => resolve(true))
  })
}


export function makeForm (params, action, method = 'post') {
  let form = document.querySelector('form[name="pay_jump_form"]')
  if (form) {
    form.parentNode?.removeChild(form)
  }

  method = method || 'post'
  form = document.createElement('form')
  form.setAttribute('name', 'pay_jump_form')
  form.setAttribute('action', action)
  form.setAttribute('method', method)

  for (const key in params) {
    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', key)
    input.setAttribute('value', params[key])
    form.appendChild(input)
  }

  const submitInput = document.createElement('input')
  submitInput.setAttribute('type', 'submit')
  submitInput.setAttribute('value', 'Continue')
  submitInput.style.display = 'none'
  form.appendChild(submitInput)

  document.body.appendChild(form)
  // @ts-ignore
  document?.pay_jump_form?.submit?.()
}

export function checkPaymentMethodSupport (paymentItem: Trade_PayToolKit.PaymentInfoItem, { isMounted } = { isMounted: false }) {
  if (paymentItem.is_apple_pay == 1 && isMounted) {
    // @ts-ignore
    return window.ApplePaySession?.canMakePayments?.()
    // return true
  }
  return true
}


export function mergeWith (target, source) {
  for (const key in source) {
    if (source[key] !== undefined) {
      target[key] = source[key]
    }
  }
  return target
}

// 合并两个对象，如果新对象的属性值为undefined，则删除原对象的属性
export function mergeSelectPaymentWithDeleteUndefined (target, newPaymentItem) {
  if (target instanceof Array || newPaymentItem instanceof Array) {
    return [...target, ...newPaymentItem]
  }
  if (typeof target !== 'object' || typeof newPaymentItem !== 'object') {
    return target
  }
  const cloneTarget = cloneDeep(target)
  for (const key in cloneTarget) {
    if (newPaymentItem[key] === undefined) {
      delete cloneTarget[key]
    }
  }
  return Object.assign(cloneTarget, newPaymentItem)
}
