import type { DS_PayToolkit } from '../../../../types'

export const fetchCodVerifyFreeApi: DS_PayToolkit.APIS_THIS['fetchCodVerifyFreeApi'] = async function (data) {
  const result = await this.$schttp({
    url: '/order/cod/verify/free',
    method: 'GET',
    headers: {
      AppCurrency: this.$envs.currency,
      AppLanguage: this.$envs.appLanguage,
    },
    params: { billno: data.billno },
  })
  return result.data
}
