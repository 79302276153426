<template>
  <div class="coupon-for-pay">
    <span
      ref="txtRef"
      class="coupon-txt">
      {{ couponTxt }}
    </span>
  </div>
</template>

<script setup>
// import { template } from '@shein/common-function'
import { computed, watch, ref, nextTick } from 'vue'

const props = defineProps({
  couponInfo: { type: Object, default: () => ({}) },
  language: { type: Object, default: () => ({}) },
})

const txtRef = ref(null)

const couponTxt = computed(() => {
  return removeLeadingMinusSign(props.couponInfo?.titles?.[0] || '')
})

function removeLeadingMinusSign(str) {
  const regex = /^-/
  return str.replace(regex, '')
}

watch(
  () => couponTxt.value,
  val => {
    if (val) {
      nextTick(() => {
        const nowIsTextOverflow = isTextOverflow(txtRef.value)
        if (nowIsTextOverflow) {
          txtRef.value?.classList?.add('mini-title')
        } else {
          txtRef.value?.classList?.remove('mini-title')
        }
      })
    }
  },
  {
    immediate: true,
  },
)

// function percentFormat(num) {
//   const newNum = (num * 100) / 100
//   const percentNum = newNum + '%'
//   return percentNum
// }

// satisfied_range 当前用户满足的最高档次：0：所有档次都不满足 1：满足第一档 2：满足第二档，3：满足第了档，4：满足第4档，以此类推
// function getCurrentCouponRule(supportBinData) {
//   // 多档券
//   if (supportBinData?.other_coupon_rule?.length > 1 && supportBinData?.satisfied_range > 0) {
//     return supportBinData?.other_coupon_rule[supportBinData?.satisfied_range - 1]
//   }
//   return supportBinData?.other_coupon_rule?.[0]
// }

// function getDiscount(couponTypeId, currentCouponRule) {
//   if (couponTypeId == '1') {
//     // 减元
//     return template(currentCouponRule?.price?.priceSymbol || '', props.language.SHEIN_KEY_PWA_15816)
//   }

//   if ([2, 5].includes(+couponTypeId)) {
//     // 折扣
//     return template(percentFormat(currentCouponRule?.price?.price || ''), props.language.SHEIN_KEY_PWA_15816)
//   }
//   return ''
// }

function isTextOverflow(element) {
  if (!element) return false
  // 检测元素的滚动宽度是否大于其可视宽度
  return element?.scrollWidth > element?.offsetWidth
}
</script>

<style lang="less">
.coupon-for-pay {
  position: relative;
  min-width: 148/37.5rem;
  max-width: 351px;
  height: 65/37.5rem;
  background: linear-gradient(180deg, #fff6f3 0%, #ffe6de 100%);
  border: 1px solid #ffb685;
  border-radius: 12px;
  margin-top: 11/37.5rem;
  color: #f6451e;
  text-align: center;
  font-family: 'SF Pro';
  font-size: 28/37.5rem;
  font-style: normal;
  font-weight: 860;
  line-height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24/37.5rem;
  box-sizing: border-box;

  .coupon-txt {
    // 超出一行展示省略号
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .mini-title {
    font-size: 14px;
  }
}

.coupons-before-after() {
  box-sizing: border-box;
  content: '';
  position: absolute;
  top: 50%;
  width: 12/75rem;
  height: 24/75rem;
  background-color: white;
  border: 2/75rem solid #ffb685;
  border-radius: 16/75rem;
  transform: translateY(-50%);
}

.coupon-for-pay::before {
  .coupons-before-after();
  left: -2/75rem;
  border-left-color: #fff;
  border-radius: 0 12/75rem 12/75rem 0;
}

.coupon-for-pay::after {
  .coupons-before-after();
  right: -2/75rem;
  border-right-color: #fff;
  border-radius: 12/75rem 0 0 12/75rem;
}
</style>
