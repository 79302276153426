<template>
  <div
    :class="{
      'installment-option': true,
      'installment-option-selected': selected
    }"
    @click="selectInstallment">
    <div class="installment-plan">
      <AdapterText
        :text="planStr"
        :min-size="12" />
    </div>
    <div
      :class="{
        'installment-fee': true,
        'installment-fee__zero': isZeroRate
      }">
      {{ feeStr }}
    </div>
    <div
      v-if="data.recommend_reason && data.default_render"
      class="installment-tag">
      {{ data.recommend_reason }}
    </div>
    <div class="selected-icon">
      <Icon
        class="selected-icon__icon"
        name="sui_icon_checkin_done_32px"
        :is-rotate="cssRight"
        size="20px" />
    </div>
  </div>
</template>

<script setup name="InstallmentItemNewStyle" lang="ts">
import { computed } from 'vue'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import { type LangKeys } from '../../../common/languages'
import { template } from '@shein/common-function'
import { AdapterText } from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

interface InstallmentItemNewStyleProps {
  language: Record<LangKeys, string>;
  data: Trade_PayToolKit.InstallmentInfoItem;
  selected: boolean;
}

const props = withDefaults(defineProps<InstallmentItemNewStyleProps>(), {
  language: () => ({} as Record<LangKeys, string>),
  data: () => ({} as Trade_PayToolKit.InstallmentInfoItem),
  selected: false,
})

const emit = defineEmits<{
  (event: 'click'): void,
}>()

const { cssRight } = useAppConfigs()?.$envs || {}

const isZeroRate = computed(() => {
  const installment_fee_numeric = props.data?.installment_fee_numeric
  return !installment_fee_numeric || Number(installment_fee_numeric) <= 0
})

const planStr = computed(() => {
  return `${props.data?.stage_num}x${props.data?.installment_amount}`
})
const feeStr = computed(() => {
  return isZeroRate.value ? props.language.BS_KEY_PAY_1161 : template(props.data.installment_fee, props.language.BS_KEY_PAY_1162)
})

const selectInstallment = () => {
  emit('click')
}

</script>
<style lang="less" scoped>
.show-one-line() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.installment-option {
  width: 171 * 2/75rem;
  border-radius: 4 * 2/75rem;
  margin-top: 12 * 2/75rem;
  padding: 13.5 * 2/75rem 8 * 2/75rem;
  background-color: #fff;
  border: 1px solid #E5E5E5;
  overflow: hidden;
  position: relative;

  .installment-plan {
    padding-bottom: 4 * 2/75rem;
    font-size: 16 * 2/75rem;
    line-height: 19 * 2/75rem;
    color: #000;
  }

  .installment-fee {
    font-size: 12 * 2/75rem;
    line-height: 14 * 2/75rem;
    color: #666;
    .show-one-line();

    &__zero {
      color: #FA6338;
    }
  }

  .installment-tag {
    max-width: calc(200% - 68rem * 2 / 75);
    height: 30 * 2/75rem;
    padding: 0 8 * 2/75rem;
    background-color: #FA6338;
    border-radius: 0 8 * 2/75rem 0 8 * 2/75rem;
    font-size: 18 * 2/75rem;
    line-height: 30 * 2/75rem;
    color: #fff;
    transform: scale(0.5);
    transform-origin: right top;
    .show-one-line();
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .selected-icon {
    display: none;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 18 * 2/75rem;
    height: 14 * 2/75rem;
    border-radius: 4 * 2/75rem 0 0 0;
    background-color: #000;
    justify-content: center;
    align-items: center;
    color: #fff;
  }

  &-selected {
    border-color: #000;

    .installment-plan {
      font-weight: bold;
    }

    .installment-fee {
      font-weight: bold;
    }

    .selected-icon {
      display: flex;
    }
  }
}
</style>
