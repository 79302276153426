import { ref } from 'vue'
export const usePrivacyDrawer = () => {
  const isShowPrivacyDrawer = ref(false)

  const showPrivacyDrawer = () => {
    isShowPrivacyDrawer.value = true
  }
  return {
    isShowPrivacyDrawer,
    showPrivacyDrawer,
  }
}
