import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
export { default as BPayApplePayButton } from './applepay-button/index.vue'
export { default as BPayPaymentOptions } from './payments/index.vue'
export { default as BPayUpgradeAppTips } from './upgrade-app-tips/index.vue'
export { default as BPaypalGaButton } from './paypal-button/PaypalGaButton.vue'
export { default as BPaypalVenmoButton } from './paypal-button/PaypalVenmoButton.vue'
// export { default as useCapitecDialog } from '../../hooks/useCapitecDialog'
export { default as BPayCodVerifyFreePopup } from './cod-verify-free-popup/index'
export { default as BPayLoading } from './pay-loading/index.vue'
export { default as BCvvDrawer } from './cvv-drawer/index.vue'
export { default as CouponsBinDrawer } from './coupons-bin-drawer/index.vue'
export { default as BPayPaymentItem } from './payment-items/cell/PaymentItem.vue'
export { EXPOSE_PREFIX } from '../../common/constants'

import type { DS_PayToolkit } from '../../types'
export type * from '../../types'

import { getFsDataRunner } from '../../common/dataSource'

export function getPayToolkitFsDataRunner(sourceParams?: Partial<DS_PayToolkit.FS_SourceParams>) {
  return getFsDataRunner({ appConfigs: useAppConfigs(), ...sourceParams })
}
