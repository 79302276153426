import { computed, watch, ref } from 'vue'
import { useItemSelectBank } from './useItemSelectBank'
import { usePaymentsStore } from './usePayments'
import { usePaymentConfig } from './usePaymentsConfig'
import { useEditablePaymentInfo } from './useEditablePaymentInfo'
import type { BPayPaymentItemProps } from '../types/props'
import { debuggerLog } from '../utils'

export const usePaymentItem = (props: BPayPaymentItemProps) => {
  const paymentStore = usePaymentsStore()
  const { getPaymentConfig } = usePaymentConfig()
  const {
    visibleBankSelect,
    selectedDisPlayBankInfo,
    handleToSelectBank,
    isHadSelectedBank,
  } = useItemSelectBank(props, paymentStore)

  const hasSelected = ref(false)

  const paymentConfig = getPaymentConfig()

  const isShowToken = computed(() => !!props.paymentItem?.card_token)

  const isInstallment = computed(() => props.paymentItem?.code === 'routepay-cardinstallment')

  const isHomoGenizationPay = computed(() => (props.paymentItem?.payments || [])?.length > 0)

  const signAccountInfo = computed(() => props.paymentItem.paymentSignUp?.[0] || {})

  // 会员、超省卡随单购
  const isPrimeOrSavePopup = computed(() => paymentConfig.forceSignUp.value)

  // 是否是平台模式
  const hasPlatFormGoods = computed(() => +paymentStore.checkoutInfo.has_platform_goods === 1)

  // ppga平台模式abt
  const platformVaultingOptionShow = computed(() => {
    return paymentStore.abtInfo?.Platformitemsvaultingoption?.param?.platform_items_vaulting_option === 'on'
  })

  const showSignup = computed(() => {
    if (props.paymentItem.code !== 'PayPal-GApaypal') {
      return props.paymentItem.needToSign === '1'
    } else {
      // ppga逻辑
      if (!signAccountInfo.value?.id) {
        if (hasPlatFormGoods.value) {
          // abt开启，展示勾选框
          return platformVaultingOptionShow.value
        } else {
          // 非平台模式, 走自营abt
          return props.paymentItem.needToSign === '1'
        }
      } else {
        return false
      }
    }
  })

  const ppgaTitle = computed(() => {
    // 非ppga支付方式
    if (props.paymentItem.code !== 'PayPal-GApaypal') return ''
    // 是ppga支付方式，非平台模式且签约abt为关闭状态
    if (props.paymentItem.needToSign !== '1' && !hasPlatFormGoods.value) return ''
    const signInfo = props.paymentItem.paymentSignUp?.[0] || {}
    // 快捷支付展示
    if (props.signUpInfo?.use_one_time_sign || showSignup.value) return paymentStore.language.BS_KEY_PAY_1027
    if (signInfo?.id) {
      return signInfo?.signUpEmail || paymentStore.language.BS_KEY_PAY_1028
    }
    return ''
  })

  const paymentTags = computed(() => {
    const tags: string[] = []
    if (props.paymentItem?.last_used) {
      tags.push(paymentStore.language.BS_KEY_PAY_1209)
    }
    return tags
  })

  const paymentItemsConfig = computed(() => props?.isOnlyPayItem ? (props?.nowPaymentItemsConfig || {}) : paymentStore.paymentItemsConfig)

  const { updateInfo, editablePaymentInfo } = useEditablePaymentInfo()

  const nowUseSelectedDisPlayBankInfo = computed(() => (props?.isOnlyPayItem && Object.keys(props?.nowSelectedDisPlayBankInfo || {}).length > 0)
  ? props?.nowSelectedDisPlayBankInfo
  : selectedDisPlayBankInfo.value)

  const nowUseEditablePaymentInfoData = computed(() =>
    (props?.isOnlyPayItem && Object.keys(props?.nowEditablePaymentInfoData || {}).length > 0)
      ? props?.nowEditablePaymentInfoData
      : editablePaymentInfo.value?.[props.paymentItem.code])

  const isAutoRenewal = computed(() => {
    return isPrimeOrSavePopup.value && (paymentItemsConfig.value?.checkoutForceRememberCardTip || paymentItemsConfig.value?.forceRememberCardTip)
  })

  const cardTokenInfo = computed(() => nowUseEditablePaymentInfoData.value?.card_token_info)
  const isUseNewCard = computed(() => nowUseEditablePaymentInfoData.value?.isUseNewCard)

  const paymentTitle = computed(() => {
    props?.isOnlyPayItem && debuggerLog('paymentTitle >>>>>>>', props?.nowSelectedDisPlayBankInfo)
    if (+props.paymentItem.show_title !== 1) return ''
    if (props.signUpInfo?.use_one_time_sign && props.paymentItem.code !== 'PayPal-GApaypal') return props.paymentItem?.oncePay_paytitle
    if (isShowToken.value && isInstallment.value) {
      if (isUseNewCard.value) {
        return props.language.BS_KEY_PAY_1030
      }
      return `${cardTokenInfo.value?.card_no}${props.language.BS_KEY_PAY_1031}`
    }
    if (isShowToken.value) return cardTokenInfo.value?.card_no
    if (nowUseSelectedDisPlayBankInfo.value?.title) return nowUseSelectedDisPlayBankInfo.value?.title
    if (ppgaTitle.value) return ppgaTitle.value
    return props.paymentItem?.title || ''
  })

  const paymentLogo = computed(() => {
    if (isShowToken.value) {
      if (isUseNewCard.value) {
        return 'https://img.ltwebstatic.com/images3_ccc/2024/06/14/aa/1718352340ff2421c5efcedb6a17bb10bd997ca733.png'
      }

      // 联名卡显示联名卡logo
      if(cardTokenInfo.value?.co_brand_card_tag === '1' && cardTokenInfo.value?.tokenCoBrandedLogoUrl){
        return cardTokenInfo.value?.tokenCoBrandedLogoUrl
      }

      return cardTokenInfo.value?.web_logo
    }
    if (nowUseSelectedDisPlayBankInfo.value?.logo) return nowUseSelectedDisPlayBankInfo.value?.logo
    if (props.paymentItem.enabled !== '1') return props.paymentItem?.gray_logo_url || ''
    return props.paymentItem?.logo_url || ''
  })

  const visiblePpgaVaultingSelect = computed(() => {
    const signInfo = signAccountInfo.value
    // if (needSelectPPGAVault.value && signInfo?.signUpEmail && props.paymentItem.needToSign === '1') {
    //   return true
    // }

    // return false
    if (signInfo?.signUpEmail) {
      // 平台模式开启
      if (hasPlatFormGoods.value) {
        return platformVaultingOptionShow.value
      } else {
        return props.paymentItem.needToSign === '1'
      }
    }

    return false
  })

  const paymentItemExposeAnalysis = computed(() => {
    const paymentCode = props.paymentItem.code
    const isDefault = paymentStore?.paymentSuggestion === paymentCode
    let isVaulting = '-'
    if (paymentCode === 'PayPal-GApaypal') {
      isVaulting = signAccountInfo.value?.signUpEmail ? '1' : '0'
    }
    // 是否存在在线支付方式优惠 discountType=2
    const isPayPromotion = props.paymentItem?.paymentsPreferentialTips?.some?.(item => +item.type === 2)
    return {
      payment_method: paymentCode,
      is_vaulting: isVaulting,
      is_folded: props.isFolded ? '1' : '0',
      is_pay_promotion: isPayPromotion ? '1' : '0',
      is_default: isDefault ? '1' : '0',
      default_type: isDefault ? '1' : '0',
      sequence: (props?.paymentIndex || -1) + 1,
    }
  })

  watch([
    paymentTitle,
    paymentLogo,
  ], ([title, logo], [oldTitle, oldLogo]) => {
    // 标题和logo变化时，更新支付方式信息
    if (title !== oldTitle || logo !== oldLogo) {
      updateInfo(props.paymentItem.code, {
        display_title: title,
        display_logo: logo,
      })
    }
  })

  return {
    hasSelected,
    paymentTitle,
    paymentLogo,
    isShowToken,
    paymentTags,
    signAccountInfo,
    visiblePpgaVaultingSelect,
    hasPlatFormGoods,
    platformVaultingOptionShow,
    showSignup,
    visibleBankSelect,
    handleToSelectBank,
    isHadSelectedBank,
    isHomoGenizationPay,
    paymentItemsConfig,
    isAutoRenewal,
    isPrimeOrSavePopup,
    paymentItemExposeAnalysis,
    nowUseEditablePaymentInfoData,
  }
}
