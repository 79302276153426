import { computed } from 'vue'
import { BPayPaymentItemProps } from '../types/props'
import type { PaymentsStore } from './usePayments'

export const useItemSelectBank = (props: BPayPaymentItemProps, paymentStore?: PaymentsStore) => {

  const bankList = computed(() => props.paymentItem?.bank_list || [])
  const visibleBankSelect = computed(() => bankList.value?.length > 0 && props.isSelected)

  const editablePaymentInfo = computed(() => paymentStore?.editablePaymentInfo)

  const bankInfo = computed(() => bankList.value.find?.(item => item.code === editablePaymentInfo.value[props.paymentItem.code]?.bank_code))

  const selectedDisPlayBankInfo = computed(() => {
    const isMatchPayment = props.isSelected
    return {
      title: isMatchPayment && bankInfo.value?.name ? bankInfo.value.name : '',
      logo: isMatchPayment && bankInfo.value?.logo ? bankInfo.value.logo : '',
    }
  })

  const isHadSelectedBank = computed(() => {
    return selectedDisPlayBankInfo.value?.title?.length > 0
  })

  const handleToSelectBank = () => {
    const banks: BPayPaymentItemProps['paymentItem']['bank_list'] = Object.assign([], bankList.value)
    banks.sort((a, b) => a.is_gray - b.is_gray)
    paymentStore?.mergeState('bankSelectInfo', {
      visible: true,
      banks,
      selectedBank: bankInfo.value,
      paymentCode: props.paymentItem.code,
    })
  }

  return {
    visibleBankSelect,
    selectedDisPlayBankInfo,
    handleToSelectBank,
    isHadSelectedBank,
  }
}

export const useValidSelectBank = (paymentStore: PaymentsStore) => {

  const editablePaymentInfo = computed(() => paymentStore?.editablePaymentInfo)
  const bankList = computed(() => paymentStore?.selectedPayment?.bank_list || [])
  const bankInfo = computed(() => bankList.value.find?.(item => item.code === editablePaymentInfo.value[paymentStore?.selectedPayment?.code]?.bank_code))

  const handleToSelectBank = () => {
    const banks: BPayPaymentItemProps['paymentItem']['bank_list'] = Object.assign([], bankList.value)
    banks.sort((a, b) => a.is_gray - b.is_gray)
    paymentStore?.mergeState('bankSelectInfo', {
      visible: true,
      banks,
      selectedBank: bankInfo.value,
      paymentCode: paymentStore.selectedPayment.code,
    })
  }

  const needValidSelectBankByPay = () => {
    if (bankList.value?.length && !bankInfo.value?.name) {
      return true
    }
    return false
  }

  const validateSelectBankByPay = () => {

    if (needValidSelectBankByPay()) {
      handleToSelectBank()
      return {
        validateResult: false,
        scene: 'select-bank',
        metric_scene: 'payment_no_select_bank',
        saParams: {
          result_reason: '1_4',
        },
      }
    }

    return {
        validateResult: true,
        scene: 'select-bank',
    }
  }

  return {
    needValidSelectBankByPay,
    validateSelectBankByPay,
  }
}
