<template>
  <SLazyMount>
    <SDialog
      :visible.sync="capitecVisible"
      :show-close="true"
      :append-to-body="true"
      class="payment-capitec-dialog"
      @close="handleClose"
      @close-from-icon="handleManualClose"
    >
      <div class="capitec-dialog-container">
        <div :class="isSpecialPayment ? 'capitec-dialog-title__special' : 'capitec-dialog-title'">
          {{ titleText }}
        </div>
        <div class="capitec-dialog-form">
          <div
            v-if="dialogConfig.needCollectPhoneNumber"
            class="form-item"
          >
            <SFieldBox
              ref="phoneRef"
              v-model="capitecDialogData.phone"
              :label="language.BS_KEY_PAY_1117"
              placeholder=""
              @blur="onPhoneInputBlur"
              @input="onPhoneInputChange"
            >
              <template #twoStagePrev>
                <div>
                  {{ dialogConfig.phoneCountryNum }}
                </div>
              </template>
            </SFieldBox>
            <p class="diy-note">
              {{ language.BS_KEY_PAY_1118 }}
            </p>
          </div>
          <div
            v-if="dialogConfig.needCollectEmail"
            class="form-item"
          >
            <SFieldBox
              v-show="!isSpecialPayment"
              ref="emailRef"
              v-model="capitecDialogData.email"
              :label="language.BS_KEY_PAY_1119"
              placeholder=""
              @blur="onEmailInputBlur"
              @input="onEmailInputChange"
            />

            <div v-show="isSpecialPayment">
              <div class="email">
                <input
                  v-model="capitecDialogData.email"
                  type="text"
                  class="email_val"
                />
              </div>
              <p
                v-show="errorTip"
                class="error-tip"
              >
                {{ errorTip }}
              </p>
            </div>

            <p
              v-show="!isSpecialPayment"
              class="diy-note"
            >
              {{ language.BS_KEY_PAY_1120 }}
            </p>
          </div>
        </div>
      </div>
      <template #footer>
        <SButton
          :type="['primary', 'H72PX']"
          :width="'100%'"
          @click="submit"
        >
          {{ btnText }}
        </SButton>
      </template>
    </SDialog>
  </SLazyMount>
</template>

<script setup lang="ts">
import {
  Dialog as SDialog,
  Button as SButton,
  FieldBox as SFieldBox,
  LazyMount as SLazyMount,
} from '@shein/sui-mobile'
import { computed, ref, watch, nextTick, inject } from 'vue'
import useCapitecDialog from '../../../hooks/useCapitecDialog'
import { AS_PayToolkit } from '../../../types'
const { triggerNotice } = inject('analysisInstance') as AS_PayToolkit.AnalysisInstance
import { usePaymentsStore } from '../../../hooks/usePayments'

interface CapitecDialogProps {
  language: Record<string, string>;
  collectionInfo: {
    pay_phone: string;
    pay_email: string;
  };
}

const paymentStore = usePaymentsStore()

const emits = defineEmits(['dialogClose', 'submit', 'pay-now'])

const props = withDefaults(defineProps<CapitecDialogProps>(), {
  language: () => ({}),
  collectionInfo: () => ({
    pay_phone: '',
    pay_email: '',
  }),
})

const specialPayment = ['ebanx-boleto', 'dlocal-boleto']

const { capitecVisible, dialogConfig, capitecDialogData, genReportConfirmReason, closeCapitecDialog } = useCapitecDialog()

const phoneRef = ref<SFieldBox>(null)

const emailRef = ref<SFieldBox>(null)

const errorTip = ref('')

const isSpecialPayment = computed(() => {
  return specialPayment.includes(dialogConfig.value.paymentCode)
})

const titleText = computed(() => {
    if (isSpecialPayment.value) {
      return props.language.BS_KEY_PAY_1214
    } else if (dialogConfig.value.needCollectEmail && dialogConfig.value.needCollectPhoneNumber) {
      return props.language.BS_KEY_PAY_1122
    } else if (dialogConfig.value.needCollectEmail) {
      return props.language.BS_KEY_PAY_1123
    } else if (dialogConfig.value.needCollectPhoneNumber) {
      return props.language.BS_KEY_PAY_1121
    }
    return ''
  })

const btnText = computed(() => {
  if (isSpecialPayment.value) {
    return props.language.BS_KEY_PAY_1215 || 'Confirm'
  } else {
    return props.language.BS_KEY_PAY_1210 || 'Confirm'
  }
})

const handleClose = () => {
  emits('dialogClose')
  dialogConfig.value.handleCancel?.()
}

const handleManualClose = () => {
  triggerNotice({
    id: 'click_close.comp_capitec_dialog',
    data: {
      payment_method: dialogConfig.value.paymentCode,
      bank_code: dialogConfig.value?.bankInfo?.code || '',
    },
  })

  paymentStore?.paymentEventBus?.paymentValidSendBi?.emit({
      result_reason: '1_14',
  })
}

const onPhoneInputBlur = () => {
  capitecDialogData.value.phoneError = 0
  if (!capitecDialogData.value.phone) {
    phoneRef.value?.updateErrorView?.({
      valid: false,
      message: props.language.BS_KEY_PAY_1124 || '',
    })
    capitecDialogData.value.phoneError = 1
    return
  }
  if (dialogConfig.value.phoneRule?.reg) {
    let valid = new RegExp(dialogConfig.value.phoneRule?.reg).test(capitecDialogData.value.phone)
    phoneRef.value?.updateErrorView?.({
      valid,
      validateMessage: dialogConfig.value.phoneRule.copywrite || '',
    })
    if (!valid) {
      capitecDialogData.value.phoneError = 2
    }
  }
}

const onPhoneInputChange = () => {
  if(!capitecDialogData.value.isPhoneChange){
    capitecDialogData.value.isPhoneChange = true
    triggerNotice({
      id: 'click_input.comp_capitec_dialog',
      data: {
        payment_method: dialogConfig.value.paymentCode,
        bank_code: dialogConfig.value?.bankInfo?.code || '',
        input_type: 'phone',
      },
    })
  }
}

const onEmailInputBlur = () => {
  capitecDialogData.value.emailError = 0
  if (!capitecDialogData.value.email) {
    emailRef.value?.updateErrorView?.({
      valid: false,
      validateMessage: props.language.BS_KEY_PAY_1125 || '',
    })
    errorTip.value = props.language.BS_KEY_PAY_1125 || ''
    capitecDialogData.value.emailError = 1
    return
  }
  if (dialogConfig.value.emailRule?.reg) {
    let valid = new RegExp(dialogConfig.value.emailRule.reg).test(capitecDialogData.value.email)
    emailRef.value?.updateErrorView?.({
      valid,
      validateMessage: props.language.BS_KEY_PAY_1126 || '',
    })
    if (!valid) {
      errorTip.value = props.language.BS_KEY_PAY_1126 || ''
      capitecDialogData.value.emailError = 2
    }
  }
}

const onEmailInputChange = () => {
  if(!capitecDialogData.value.isEmailChange){
    capitecDialogData.value.isEmailChange = true
    triggerNotice({
      id: 'click_input.comp_capitec_dialog',
      data: {
        payment_method: dialogConfig.value.paymentCode,
        bank_code: dialogConfig.value?.bankInfo?.code || '',
        input_type: 'email',
      },
    })
  }
}

const validateInput = () => {
  if (dialogConfig.value.needCollectEmail && dialogConfig.value.needCollectPhoneNumber) {
    onPhoneInputBlur()
    onEmailInputBlur()
    return !capitecDialogData.value.phoneError && !capitecDialogData.value.emailError
  } else if (dialogConfig.value.needCollectEmail) {
    onEmailInputBlur()
    return !capitecDialogData.value.emailError
  } else if (dialogConfig.value.needCollectPhoneNumber) {
    onPhoneInputBlur()
    return !capitecDialogData.value.phoneError
  }
}

const submit = () => {
  let isOk = validateInput()
  triggerNotice({
    id: 'click_confirm.comp_capitec_dialog',
    data: {
      payment_method: dialogConfig.value.paymentCode,
      bank_code: dialogConfig.value?.bankInfo?.code || '',
      result: isOk ? '1' : '0',
      result_reason: genReportConfirmReason(),
      revise: (capitecDialogData.value.isPhoneChange || capitecDialogData.value.isEmailChange) ? '1' : '0',
    },
  })

  if (isOk) {
    const collectInfo = {
      phone: capitecDialogData.value.phone,
      email: capitecDialogData.value.email,
    }
    closeCapitecDialog()
    if (typeof dialogConfig.value.handleConfirm === 'function') {
      dialogConfig.value.handleConfirm(collectInfo)
    } else {
      emits('submit', {
        paymentCode: dialogConfig.value.paymentCode,
        collectInfo,
      })
    }
    if (dialogConfig.value.needContinuePay) {
      emits('pay-now')
    }
  }
}

const init = () => {
  capitecDialogData.value.isEmailChange = false
  capitecDialogData.value.isPhoneChange = false
  if (!capitecDialogData.value.phone) {
    capitecDialogData.value.phone = props.collectionInfo.pay_phone || ''
  }
  if (!capitecDialogData.value.email) {
    capitecDialogData.value.email = props.collectionInfo.pay_email || ''
  }

  triggerNotice({
    id: 'expose_info_popup.comp_capitec_dialog',
    data: {
      payment_method: dialogConfig.value.paymentCode,
      bank_code: dialogConfig.value?.bankInfo?.code || '',
    },
  })

  nextTick(() => {
    phoneRef.value?.updateErrorView?.({
      valid: true,
      message: '',
    })
    emailRef.value?.updateErrorView?.({
      valid: true,
      validateMessage: '',
    })
  })
}

watch(capitecVisible, (val) => {
  if (val) {
    init()
  }
}, { immediate: true })


</script>

<style lang="less" scoped>
.payment-capitec-dialog {
  /deep/ .S-dialog__normal {
    width: 290/37.5rem;
    .S-dialog__footer {
      padding-top: 16/37.5rem;
      padding-bottom: 16/37.5rem;
    }
  }
}
.capitec-dialog-container {
  .capitec-dialog-title {
    color: var(---sui_color_gray_dark1, #000);
    text-align: center;
    font-family: 'SF UI Text';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .capitec-dialog-title__special {
    color: #222;
    margin: .2rem 0;
    font-size: 12px;
    text-align: center;
  }
  .capitec-dialog-form {
    margin-top: 20/37.5rem;
    .form-item {
      margin-bottom: 12/37.5rem;
    }
    .diy-note {
      margin-top: 4px;
      color: #999;
      font-family: 'SF UI Display';
      font-size: 12/37.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .email {
    display: flex;
    border: 1px solid #ccc;
    height: 1.17rem;
    line-height: 1.17rem;
    margin-top: 0.4rem;

    .email_val {
      border: 0;
      margin: 0;
      height: 100%;
      font-weight: 700;
      flex: 1;
      padding-left: 0.1rem;
    }
  }

  .error-tip {
    color: #e64545;
    margin-top: 0.2rem;
    text-align: left;
  }
}
</style>
