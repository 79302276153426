import { ref } from 'vue'
import type { Ref } from 'vue'
import useApis from './useApis'

const useCardBinDiscountArticle = () => {
  const { getCardBinDiscountArticleApi } = useApis()

  const showLoading = ref(false)
  const articleInfo: Ref<{
    conText?: string
    conName?: string
  }> = ref({})

  async function getArticleInfo(articleId: string) {
    if (articleInfo.value?.conText) return
    showLoading.value = true

    try {
      const res = await getCardBinDiscountArticleApi({ articleId: Number(articleId) })
      articleInfo.value = res?.articleInfo || {}
      showLoading.value = false
    } catch (_) {
      showLoading.value = false
      articleInfo.value = {}
    }
  }

  return {
    showLoading,
    articleInfo,
    getArticleInfo,
  }
}

export default useCardBinDiscountArticle
